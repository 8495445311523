import { template as template_d2a4d3b2436b4ef7904e978039c038b1 } from "@ember/template-compiler";
import Component from '@glimmer/component';
export default class ChallengeLayout extends Component {
    get className() {
        return this.args.color ?? 'default';
    }
    static{
        template_d2a4d3b2436b4ef7904e978039c038b1(`
    <div class="challenge-layout challenge-layout--{{this.className}}">
      <div class="container">
        {{yield}}
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
