/**
* @vue/shared v3.5.12
* (c) 2018-present Yuxi (Evan) You and Vue contributors
* @license MIT
**/
/*! #__NO_SIDE_EFFECTS__ */
// @__NO_SIDE_EFFECTS__
function As(e) {
  const t = /* @__PURE__ */ Object.create(null);
  for (const s of e.split(",")) t[s] = 1;
  return (s) => s in t;
}
const U = {}, Ye = [], Ce = () => {
}, Bi = () => !1, Bt = (e) => e.charCodeAt(0) === 111 && e.charCodeAt(1) === 110 && // uppercase letter
(e.charCodeAt(2) > 122 || e.charCodeAt(2) < 97), Os = (e) => e.startsWith("onUpdate:"), k = Object.assign, Rs = (e, t) => {
  const s = e.indexOf(t);
  s > -1 && e.splice(s, 1);
}, Wi = Object.prototype.hasOwnProperty, N = (e, t) => Wi.call(e, t), O = Array.isArray, Qe = (e) => Wt(e) === "[object Map]", Nn = (e) => Wt(e) === "[object Set]", R = (e) => typeof e == "function", G = (e) => typeof e == "string", $e = (e) => typeof e == "symbol", B = (e) => e !== null && typeof e == "object", Dn = (e) => (B(e) || R(e)) && R(e.then) && R(e.catch), $n = Object.prototype.toString, Wt = (e) => $n.call(e), qi = (e) => Wt(e).slice(8, -1), qt = (e) => Wt(e) === "[object Object]", Ms = (e) => G(e) && e !== "NaN" && e[0] !== "-" && "" + parseInt(e, 10) === e, ft = /* @__PURE__ */ As(
  // the leading comma is intentional so empty string "" is also included
  ",key,ref,ref_for,ref_key,onVnodeBeforeMount,onVnodeMounted,onVnodeBeforeUpdate,onVnodeUpdated,onVnodeBeforeUnmount,onVnodeUnmounted"
), kt = (e) => {
  const t = /* @__PURE__ */ Object.create(null);
  return (s) => t[s] || (t[s] = e(s));
}, ki = /-(\w)/g, de = kt(
  (e) => e.replace(ki, (t, s) => s ? s.toUpperCase() : "")
), Gi = /\B([A-Z])/g, fe = kt(
  (e) => e.replace(Gi, "-$1").toLowerCase()
), jn = kt((e) => e.charAt(0).toUpperCase() + e.slice(1)), ts = kt(
  (e) => e ? `on${jn(e)}` : ""
), De = (e, t) => !Object.is(e, t), ss = (e, ...t) => {
  for (let s = 0; s < e.length; s++)
    e[s](...t);
}, Hn = (e, t, s, n = !1) => {
  Object.defineProperty(e, t, {
    configurable: !0,
    enumerable: !1,
    writable: n,
    value: s
  });
}, Ji = (e) => {
  const t = parseFloat(e);
  return isNaN(t) ? e : t;
}, nn = (e) => {
  const t = G(e) ? Number(e) : NaN;
  return isNaN(t) ? e : t;
};
let rn;
const Gt = () => rn || (rn = typeof globalThis < "u" ? globalThis : typeof self < "u" ? self : typeof window < "u" ? window : typeof global < "u" ? global : {});
function Is(e) {
  if (O(e)) {
    const t = {};
    for (let s = 0; s < e.length; s++) {
      const n = e[s], i = G(n) ? Xi(n) : Is(n);
      if (i)
        for (const r in i)
          t[r] = i[r];
    }
    return t;
  } else if (G(e) || B(e))
    return e;
}
const zi = /;(?![^(]*\))/g, Yi = /:([^]+)/, Qi = /\/\*[^]*?\*\//g;
function Xi(e) {
  const t = {};
  return e.replace(Qi, "").split(zi).forEach((s) => {
    if (s) {
      const n = s.split(Yi);
      n.length > 1 && (t[n[0].trim()] = n[1].trim());
    }
  }), t;
}
function _t(e) {
  let t = "";
  if (G(e))
    t = e;
  else if (O(e))
    for (let s = 0; s < e.length; s++) {
      const n = _t(e[s]);
      n && (t += n + " ");
    }
  else if (B(e))
    for (const s in e)
      e[s] && (t += s + " ");
  return t.trim();
}
const Zi = "itemscope,allowfullscreen,formnovalidate,ismap,nomodule,novalidate,readonly", er = /* @__PURE__ */ As(Zi);
function Ln(e) {
  return !!e || e === "";
}
const Kn = (e) => !!(e && e.__v_isRef === !0), Un = (e) => G(e) ? e : e == null ? "" : O(e) || B(e) && (e.toString === $n || !R(e.toString)) ? Kn(e) ? Un(e.value) : JSON.stringify(e, Vn, 2) : String(e), Vn = (e, t) => Kn(t) ? Vn(e, t.value) : Qe(t) ? {
  [`Map(${t.size})`]: [...t.entries()].reduce(
    (s, [n, i], r) => (s[ns(n, r) + " =>"] = i, s),
    {}
  )
} : Nn(t) ? {
  [`Set(${t.size})`]: [...t.values()].map((s) => ns(s))
} : $e(t) ? ns(t) : B(t) && !O(t) && !qt(t) ? String(t) : t, ns = (e, t = "") => {
  var s;
  return (
    // Symbol.description in es2019+ so we need to cast here to pass
    // the lib: es2016 check
    $e(e) ? `Symbol(${(s = e.description) != null ? s : t})` : e
  );
};
/**
* @vue/reactivity v3.5.12
* (c) 2018-present Yuxi (Evan) You and Vue contributors
* @license MIT
**/
let ce;
class tr {
  constructor(t = !1) {
    this.detached = t, this._active = !0, this.effects = [], this.cleanups = [], this._isPaused = !1, this.parent = ce, !t && ce && (this.index = (ce.scopes || (ce.scopes = [])).push(
      this
    ) - 1);
  }
  get active() {
    return this._active;
  }
  pause() {
    if (this._active) {
      this._isPaused = !0;
      let t, s;
      if (this.scopes)
        for (t = 0, s = this.scopes.length; t < s; t++)
          this.scopes[t].pause();
      for (t = 0, s = this.effects.length; t < s; t++)
        this.effects[t].pause();
    }
  }
  /**
   * Resumes the effect scope, including all child scopes and effects.
   */
  resume() {
    if (this._active && this._isPaused) {
      this._isPaused = !1;
      let t, s;
      if (this.scopes)
        for (t = 0, s = this.scopes.length; t < s; t++)
          this.scopes[t].resume();
      for (t = 0, s = this.effects.length; t < s; t++)
        this.effects[t].resume();
    }
  }
  run(t) {
    if (this._active) {
      const s = ce;
      try {
        return ce = this, t();
      } finally {
        ce = s;
      }
    }
  }
  /**
   * This should only be called on non-detached scopes
   * @internal
   */
  on() {
    ce = this;
  }
  /**
   * This should only be called on non-detached scopes
   * @internal
   */
  off() {
    ce = this.parent;
  }
  stop(t) {
    if (this._active) {
      let s, n;
      for (s = 0, n = this.effects.length; s < n; s++)
        this.effects[s].stop();
      for (s = 0, n = this.cleanups.length; s < n; s++)
        this.cleanups[s]();
      if (this.scopes)
        for (s = 0, n = this.scopes.length; s < n; s++)
          this.scopes[s].stop(!0);
      if (!this.detached && this.parent && !t) {
        const i = this.parent.scopes.pop();
        i && i !== this && (this.parent.scopes[this.index] = i, i.index = this.index);
      }
      this.parent = void 0, this._active = !1;
    }
  }
}
function sr() {
  return ce;
}
let K;
const is = /* @__PURE__ */ new WeakSet();
class Bn {
  constructor(t) {
    this.fn = t, this.deps = void 0, this.depsTail = void 0, this.flags = 5, this.next = void 0, this.cleanup = void 0, this.scheduler = void 0, ce && ce.active && ce.effects.push(this);
  }
  pause() {
    this.flags |= 64;
  }
  resume() {
    this.flags & 64 && (this.flags &= -65, is.has(this) && (is.delete(this), this.trigger()));
  }
  /**
   * @internal
   */
  notify() {
    this.flags & 2 && !(this.flags & 32) || this.flags & 8 || qn(this);
  }
  run() {
    if (!(this.flags & 1))
      return this.fn();
    this.flags |= 2, on(this), kn(this);
    const t = K, s = pe;
    K = this, pe = !0;
    try {
      return this.fn();
    } finally {
      Gn(this), K = t, pe = s, this.flags &= -3;
    }
  }
  stop() {
    if (this.flags & 1) {
      for (let t = this.deps; t; t = t.nextDep)
        Ds(t);
      this.deps = this.depsTail = void 0, on(this), this.onStop && this.onStop(), this.flags &= -2;
    }
  }
  trigger() {
    this.flags & 64 ? is.add(this) : this.scheduler ? this.scheduler() : this.runIfDirty();
  }
  /**
   * @internal
   */
  runIfDirty() {
    gs(this) && this.run();
  }
  get dirty() {
    return gs(this);
  }
}
let Wn = 0, ut, at;
function qn(e, t = !1) {
  if (e.flags |= 8, t) {
    e.next = at, at = e;
    return;
  }
  e.next = ut, ut = e;
}
function Fs() {
  Wn++;
}
function Ns() {
  if (--Wn > 0)
    return;
  if (at) {
    let t = at;
    for (at = void 0; t; ) {
      const s = t.next;
      t.next = void 0, t.flags &= -9, t = s;
    }
  }
  let e;
  for (; ut; ) {
    let t = ut;
    for (ut = void 0; t; ) {
      const s = t.next;
      if (t.next = void 0, t.flags &= -9, t.flags & 1)
        try {
          t.trigger();
        } catch (n) {
          e || (e = n);
        }
      t = s;
    }
  }
  if (e) throw e;
}
function kn(e) {
  for (let t = e.deps; t; t = t.nextDep)
    t.version = -1, t.prevActiveLink = t.dep.activeLink, t.dep.activeLink = t;
}
function Gn(e) {
  let t, s = e.depsTail, n = s;
  for (; n; ) {
    const i = n.prevDep;
    n.version === -1 ? (n === s && (s = i), Ds(n), nr(n)) : t = n, n.dep.activeLink = n.prevActiveLink, n.prevActiveLink = void 0, n = i;
  }
  e.deps = t, e.depsTail = s;
}
function gs(e) {
  for (let t = e.deps; t; t = t.nextDep)
    if (t.dep.version !== t.version || t.dep.computed && (Jn(t.dep.computed) || t.dep.version !== t.version))
      return !0;
  return !!e._dirty;
}
function Jn(e) {
  if (e.flags & 4 && !(e.flags & 16) || (e.flags &= -17, e.globalVersion === mt))
    return;
  e.globalVersion = mt;
  const t = e.dep;
  if (e.flags |= 2, t.version > 0 && !e.isSSR && e.deps && !gs(e)) {
    e.flags &= -3;
    return;
  }
  const s = K, n = pe;
  K = e, pe = !0;
  try {
    kn(e);
    const i = e.fn(e._value);
    (t.version === 0 || De(i, e._value)) && (e._value = i, t.version++);
  } catch (i) {
    throw t.version++, i;
  } finally {
    K = s, pe = n, Gn(e), e.flags &= -3;
  }
}
function Ds(e, t = !1) {
  const { dep: s, prevSub: n, nextSub: i } = e;
  if (n && (n.nextSub = i, e.prevSub = void 0), i && (i.prevSub = n, e.nextSub = void 0), s.subs === e && (s.subs = n, !n && s.computed)) {
    s.computed.flags &= -5;
    for (let r = s.computed.deps; r; r = r.nextDep)
      Ds(r, !0);
  }
  !t && !--s.sc && s.map && s.map.delete(s.key);
}
function nr(e) {
  const { prevDep: t, nextDep: s } = e;
  t && (t.nextDep = s, e.prevDep = void 0), s && (s.prevDep = t, e.nextDep = void 0);
}
let pe = !0;
const zn = [];
function je() {
  zn.push(pe), pe = !1;
}
function He() {
  const e = zn.pop();
  pe = e === void 0 ? !0 : e;
}
function on(e) {
  const { cleanup: t } = e;
  if (e.cleanup = void 0, t) {
    const s = K;
    K = void 0;
    try {
      t();
    } finally {
      K = s;
    }
  }
}
let mt = 0;
class ir {
  constructor(t, s) {
    this.sub = t, this.dep = s, this.version = s.version, this.nextDep = this.prevDep = this.nextSub = this.prevSub = this.prevActiveLink = void 0;
  }
}
class $s {
  constructor(t) {
    this.computed = t, this.version = 0, this.activeLink = void 0, this.subs = void 0, this.map = void 0, this.key = void 0, this.sc = 0;
  }
  track(t) {
    if (!K || !pe || K === this.computed)
      return;
    let s = this.activeLink;
    if (s === void 0 || s.sub !== K)
      s = this.activeLink = new ir(K, this), K.deps ? (s.prevDep = K.depsTail, K.depsTail.nextDep = s, K.depsTail = s) : K.deps = K.depsTail = s, Yn(s);
    else if (s.version === -1 && (s.version = this.version, s.nextDep)) {
      const n = s.nextDep;
      n.prevDep = s.prevDep, s.prevDep && (s.prevDep.nextDep = n), s.prevDep = K.depsTail, s.nextDep = void 0, K.depsTail.nextDep = s, K.depsTail = s, K.deps === s && (K.deps = n);
    }
    return s;
  }
  trigger(t) {
    this.version++, mt++, this.notify(t);
  }
  notify(t) {
    Fs();
    try {
      for (let s = this.subs; s; s = s.prevSub)
        s.sub.notify() && s.sub.dep.notify();
    } finally {
      Ns();
    }
  }
}
function Yn(e) {
  if (e.dep.sc++, e.sub.flags & 4) {
    const t = e.dep.computed;
    if (t && !e.dep.subs) {
      t.flags |= 20;
      for (let n = t.deps; n; n = n.nextDep)
        Yn(n);
    }
    const s = e.dep.subs;
    s !== e && (e.prevSub = s, s && (s.nextSub = e)), e.dep.subs = e;
  }
}
const _s = /* @__PURE__ */ new WeakMap(), qe = Symbol(
  ""
), ms = Symbol(
  ""
), bt = Symbol(
  ""
);
function Y(e, t, s) {
  if (pe && K) {
    let n = _s.get(e);
    n || _s.set(e, n = /* @__PURE__ */ new Map());
    let i = n.get(s);
    i || (n.set(s, i = new $s()), i.map = n, i.key = s), i.track();
  }
}
function Pe(e, t, s, n, i, r) {
  const o = _s.get(e);
  if (!o) {
    mt++;
    return;
  }
  const l = (f) => {
    f && f.trigger();
  };
  if (Fs(), t === "clear")
    o.forEach(l);
  else {
    const f = O(e), h = f && Ms(s);
    if (f && s === "length") {
      const a = Number(n);
      o.forEach((p, C) => {
        (C === "length" || C === bt || !$e(C) && C >= a) && l(p);
      });
    } else
      switch ((s !== void 0 || o.has(void 0)) && l(o.get(s)), h && l(o.get(bt)), t) {
        case "add":
          f ? h && l(o.get("length")) : (l(o.get(qe)), Qe(e) && l(o.get(ms)));
          break;
        case "delete":
          f || (l(o.get(qe)), Qe(e) && l(o.get(ms)));
          break;
        case "set":
          Qe(e) && l(o.get(qe));
          break;
      }
  }
  Ns();
}
function Ge(e) {
  const t = D(e);
  return t === e ? t : (Y(t, "iterate", bt), ae(e) ? t : t.map(Q));
}
function Jt(e) {
  return Y(e = D(e), "iterate", bt), e;
}
const rr = {
  __proto__: null,
  [Symbol.iterator]() {
    return rs(this, Symbol.iterator, Q);
  },
  concat(...e) {
    return Ge(this).concat(
      ...e.map((t) => O(t) ? Ge(t) : t)
    );
  },
  entries() {
    return rs(this, "entries", (e) => (e[1] = Q(e[1]), e));
  },
  every(e, t) {
    return Te(this, "every", e, t, void 0, arguments);
  },
  filter(e, t) {
    return Te(this, "filter", e, t, (s) => s.map(Q), arguments);
  },
  find(e, t) {
    return Te(this, "find", e, t, Q, arguments);
  },
  findIndex(e, t) {
    return Te(this, "findIndex", e, t, void 0, arguments);
  },
  findLast(e, t) {
    return Te(this, "findLast", e, t, Q, arguments);
  },
  findLastIndex(e, t) {
    return Te(this, "findLastIndex", e, t, void 0, arguments);
  },
  // flat, flatMap could benefit from ARRAY_ITERATE but are not straight-forward to implement
  forEach(e, t) {
    return Te(this, "forEach", e, t, void 0, arguments);
  },
  includes(...e) {
    return os(this, "includes", e);
  },
  indexOf(...e) {
    return os(this, "indexOf", e);
  },
  join(e) {
    return Ge(this).join(e);
  },
  // keys() iterator only reads `length`, no optimisation required
  lastIndexOf(...e) {
    return os(this, "lastIndexOf", e);
  },
  map(e, t) {
    return Te(this, "map", e, t, void 0, arguments);
  },
  pop() {
    return ot(this, "pop");
  },
  push(...e) {
    return ot(this, "push", e);
  },
  reduce(e, ...t) {
    return ln(this, "reduce", e, t);
  },
  reduceRight(e, ...t) {
    return ln(this, "reduceRight", e, t);
  },
  shift() {
    return ot(this, "shift");
  },
  // slice could use ARRAY_ITERATE but also seems to beg for range tracking
  some(e, t) {
    return Te(this, "some", e, t, void 0, arguments);
  },
  splice(...e) {
    return ot(this, "splice", e);
  },
  toReversed() {
    return Ge(this).toReversed();
  },
  toSorted(e) {
    return Ge(this).toSorted(e);
  },
  toSpliced(...e) {
    return Ge(this).toSpliced(...e);
  },
  unshift(...e) {
    return ot(this, "unshift", e);
  },
  values() {
    return rs(this, "values", Q);
  }
};
function rs(e, t, s) {
  const n = Jt(e), i = n[t]();
  return n !== e && !ae(e) && (i._next = i.next, i.next = () => {
    const r = i._next();
    return r.value && (r.value = s(r.value)), r;
  }), i;
}
const or = Array.prototype;
function Te(e, t, s, n, i, r) {
  const o = Jt(e), l = o !== e && !ae(e), f = o[t];
  if (f !== or[t]) {
    const p = f.apply(e, r);
    return l ? Q(p) : p;
  }
  let h = s;
  o !== e && (l ? h = function(p, C) {
    return s.call(this, Q(p), C, e);
  } : s.length > 2 && (h = function(p, C) {
    return s.call(this, p, C, e);
  }));
  const a = f.call(o, h, n);
  return l && i ? i(a) : a;
}
function ln(e, t, s, n) {
  const i = Jt(e);
  let r = s;
  return i !== e && (ae(e) ? s.length > 3 && (r = function(o, l, f) {
    return s.call(this, o, l, f, e);
  }) : r = function(o, l, f) {
    return s.call(this, o, Q(l), f, e);
  }), i[t](r, ...n);
}
function os(e, t, s) {
  const n = D(e);
  Y(n, "iterate", bt);
  const i = n[t](...s);
  return (i === -1 || i === !1) && Ks(s[0]) ? (s[0] = D(s[0]), n[t](...s)) : i;
}
function ot(e, t, s = []) {
  je(), Fs();
  const n = D(e)[t].apply(e, s);
  return Ns(), He(), n;
}
const lr = /* @__PURE__ */ As("__proto__,__v_isRef,__isVue"), Qn = new Set(
  /* @__PURE__ */ Object.getOwnPropertyNames(Symbol).filter((e) => e !== "arguments" && e !== "caller").map((e) => Symbol[e]).filter($e)
);
function cr(e) {
  $e(e) || (e = String(e));
  const t = D(this);
  return Y(t, "has", e), t.hasOwnProperty(e);
}
class Xn {
  constructor(t = !1, s = !1) {
    this._isReadonly = t, this._isShallow = s;
  }
  get(t, s, n) {
    const i = this._isReadonly, r = this._isShallow;
    if (s === "__v_isReactive")
      return !i;
    if (s === "__v_isReadonly")
      return i;
    if (s === "__v_isShallow")
      return r;
    if (s === "__v_raw")
      return n === (i ? r ? br : si : r ? ti : ei).get(t) || // receiver is not the reactive proxy, but has the same prototype
      // this means the receiver is a user proxy of the reactive proxy
      Object.getPrototypeOf(t) === Object.getPrototypeOf(n) ? t : void 0;
    const o = O(t);
    if (!i) {
      let f;
      if (o && (f = rr[s]))
        return f;
      if (s === "hasOwnProperty")
        return cr;
    }
    const l = Reflect.get(
      t,
      s,
      // if this is a proxy wrapping a ref, return methods using the raw ref
      // as receiver so that we don't have to call `toRaw` on the ref in all
      // its class methods
      X(t) ? t : n
    );
    return ($e(s) ? Qn.has(s) : lr(s)) || (i || Y(t, "get", s), r) ? l : X(l) ? o && Ms(s) ? l : l.value : B(l) ? i ? ni(l) : Hs(l) : l;
  }
}
class Zn extends Xn {
  constructor(t = !1) {
    super(!1, t);
  }
  set(t, s, n, i) {
    let r = t[s];
    if (!this._isShallow) {
      const f = ke(r);
      if (!ae(n) && !ke(n) && (r = D(r), n = D(n)), !O(t) && X(r) && !X(n))
        return f ? !1 : (r.value = n, !0);
    }
    const o = O(t) && Ms(s) ? Number(s) < t.length : N(t, s), l = Reflect.set(
      t,
      s,
      n,
      X(t) ? t : i
    );
    return t === D(i) && (o ? De(n, r) && Pe(t, "set", s, n) : Pe(t, "add", s, n)), l;
  }
  deleteProperty(t, s) {
    const n = N(t, s);
    t[s];
    const i = Reflect.deleteProperty(t, s);
    return i && n && Pe(t, "delete", s, void 0), i;
  }
  has(t, s) {
    const n = Reflect.has(t, s);
    return (!$e(s) || !Qn.has(s)) && Y(t, "has", s), n;
  }
  ownKeys(t) {
    return Y(
      t,
      "iterate",
      O(t) ? "length" : qe
    ), Reflect.ownKeys(t);
  }
}
class fr extends Xn {
  constructor(t = !1) {
    super(!0, t);
  }
  set(t, s) {
    return !0;
  }
  deleteProperty(t, s) {
    return !0;
  }
}
const ur = /* @__PURE__ */ new Zn(), ar = /* @__PURE__ */ new fr(), hr = /* @__PURE__ */ new Zn(!0);
const bs = (e) => e, Rt = (e) => Reflect.getPrototypeOf(e);
function dr(e, t, s) {
  return function(...n) {
    const i = this.__v_raw, r = D(i), o = Qe(r), l = e === "entries" || e === Symbol.iterator && o, f = e === "keys" && o, h = i[e](...n), a = s ? bs : t ? ys : Q;
    return !t && Y(
      r,
      "iterate",
      f ? ms : qe
    ), {
      // iterator protocol
      next() {
        const { value: p, done: C } = h.next();
        return C ? { value: p, done: C } : {
          value: l ? [a(p[0]), a(p[1])] : a(p),
          done: C
        };
      },
      // iterable protocol
      [Symbol.iterator]() {
        return this;
      }
    };
  };
}
function Mt(e) {
  return function(...t) {
    return e === "delete" ? !1 : e === "clear" ? void 0 : this;
  };
}
function pr(e, t) {
  const s = {
    get(i) {
      const r = this.__v_raw, o = D(r), l = D(i);
      e || (De(i, l) && Y(o, "get", i), Y(o, "get", l));
      const { has: f } = Rt(o), h = t ? bs : e ? ys : Q;
      if (f.call(o, i))
        return h(r.get(i));
      if (f.call(o, l))
        return h(r.get(l));
      r !== o && r.get(i);
    },
    get size() {
      const i = this.__v_raw;
      return !e && Y(D(i), "iterate", qe), Reflect.get(i, "size", i);
    },
    has(i) {
      const r = this.__v_raw, o = D(r), l = D(i);
      return e || (De(i, l) && Y(o, "has", i), Y(o, "has", l)), i === l ? r.has(i) : r.has(i) || r.has(l);
    },
    forEach(i, r) {
      const o = this, l = o.__v_raw, f = D(l), h = t ? bs : e ? ys : Q;
      return !e && Y(f, "iterate", qe), l.forEach((a, p) => i.call(r, h(a), h(p), o));
    }
  };
  return k(
    s,
    e ? {
      add: Mt("add"),
      set: Mt("set"),
      delete: Mt("delete"),
      clear: Mt("clear")
    } : {
      add(i) {
        !t && !ae(i) && !ke(i) && (i = D(i));
        const r = D(this);
        return Rt(r).has.call(r, i) || (r.add(i), Pe(r, "add", i, i)), this;
      },
      set(i, r) {
        !t && !ae(r) && !ke(r) && (r = D(r));
        const o = D(this), { has: l, get: f } = Rt(o);
        let h = l.call(o, i);
        h || (i = D(i), h = l.call(o, i));
        const a = f.call(o, i);
        return o.set(i, r), h ? De(r, a) && Pe(o, "set", i, r) : Pe(o, "add", i, r), this;
      },
      delete(i) {
        const r = D(this), { has: o, get: l } = Rt(r);
        let f = o.call(r, i);
        f || (i = D(i), f = o.call(r, i)), l && l.call(r, i);
        const h = r.delete(i);
        return f && Pe(r, "delete", i, void 0), h;
      },
      clear() {
        const i = D(this), r = i.size !== 0, o = i.clear();
        return r && Pe(
          i,
          "clear",
          void 0,
          void 0
        ), o;
      }
    }
  ), [
    "keys",
    "values",
    "entries",
    Symbol.iterator
  ].forEach((i) => {
    s[i] = dr(i, e, t);
  }), s;
}
function js(e, t) {
  const s = pr(e, t);
  return (n, i, r) => i === "__v_isReactive" ? !e : i === "__v_isReadonly" ? e : i === "__v_raw" ? n : Reflect.get(
    N(s, i) && i in n ? s : n,
    i,
    r
  );
}
const gr = {
  get: /* @__PURE__ */ js(!1, !1)
}, _r = {
  get: /* @__PURE__ */ js(!1, !0)
}, mr = {
  get: /* @__PURE__ */ js(!0, !1)
};
const ei = /* @__PURE__ */ new WeakMap(), ti = /* @__PURE__ */ new WeakMap(), si = /* @__PURE__ */ new WeakMap(), br = /* @__PURE__ */ new WeakMap();
function yr(e) {
  switch (e) {
    case "Object":
    case "Array":
      return 1;
    case "Map":
    case "Set":
    case "WeakMap":
    case "WeakSet":
      return 2;
    default:
      return 0;
  }
}
function xr(e) {
  return e.__v_skip || !Object.isExtensible(e) ? 0 : yr(qi(e));
}
function Hs(e) {
  return ke(e) ? e : Ls(
    e,
    !1,
    ur,
    gr,
    ei
  );
}
function vr(e) {
  return Ls(
    e,
    !1,
    hr,
    _r,
    ti
  );
}
function ni(e) {
  return Ls(
    e,
    !0,
    ar,
    mr,
    si
  );
}
function Ls(e, t, s, n, i) {
  if (!B(e) || e.__v_raw && !(t && e.__v_isReactive))
    return e;
  const r = i.get(e);
  if (r)
    return r;
  const o = xr(e);
  if (o === 0)
    return e;
  const l = new Proxy(
    e,
    o === 2 ? n : s
  );
  return i.set(e, l), l;
}
function Xe(e) {
  return ke(e) ? Xe(e.__v_raw) : !!(e && e.__v_isReactive);
}
function ke(e) {
  return !!(e && e.__v_isReadonly);
}
function ae(e) {
  return !!(e && e.__v_isShallow);
}
function Ks(e) {
  return e ? !!e.__v_raw : !1;
}
function D(e) {
  const t = e && e.__v_raw;
  return t ? D(t) : e;
}
function wr(e) {
  return !N(e, "__v_skip") && Object.isExtensible(e) && Hn(e, "__v_skip", !0), e;
}
const Q = (e) => B(e) ? Hs(e) : e, ys = (e) => B(e) ? ni(e) : e;
function X(e) {
  return e ? e.__v_isRef === !0 : !1;
}
function Cr(e) {
  return ii(e, !1);
}
function Sr(e) {
  return ii(e, !0);
}
function ii(e, t) {
  return X(e) ? e : new Tr(e, t);
}
class Tr {
  constructor(t, s) {
    this.dep = new $s(), this.__v_isRef = !0, this.__v_isShallow = !1, this._rawValue = s ? t : D(t), this._value = s ? t : Q(t), this.__v_isShallow = s;
  }
  get value() {
    return this.dep.track(), this._value;
  }
  set value(t) {
    const s = this._rawValue, n = this.__v_isShallow || ae(t) || ke(t);
    t = n ? t : D(t), De(t, s) && (this._rawValue = t, this._value = n ? t : Q(t), this.dep.trigger());
  }
}
function ri(e) {
  return X(e) ? e.value : e;
}
const Er = {
  get: (e, t, s) => t === "__v_raw" ? e : ri(Reflect.get(e, t, s)),
  set: (e, t, s, n) => {
    const i = e[t];
    return X(i) && !X(s) ? (i.value = s, !0) : Reflect.set(e, t, s, n);
  }
};
function oi(e) {
  return Xe(e) ? e : new Proxy(e, Er);
}
class Pr {
  constructor(t, s, n) {
    this.fn = t, this.setter = s, this._value = void 0, this.dep = new $s(this), this.__v_isRef = !0, this.deps = void 0, this.depsTail = void 0, this.flags = 16, this.globalVersion = mt - 1, this.next = void 0, this.effect = this, this.__v_isReadonly = !s, this.isSSR = n;
  }
  /**
   * @internal
   */
  notify() {
    if (this.flags |= 16, !(this.flags & 8) && // avoid infinite self recursion
    K !== this)
      return qn(this, !0), !0;
  }
  get value() {
    const t = this.dep.track();
    return Jn(this), t && (t.version = this.dep.version), this._value;
  }
  set value(t) {
    this.setter && this.setter(t);
  }
}
function Ar(e, t, s = !1) {
  let n, i;
  return R(e) ? n = e : (n = e.get, i = e.set), new Pr(n, i, s);
}
const It = {}, Ht = /* @__PURE__ */ new WeakMap();
let We;
function Or(e, t = !1, s = We) {
  if (s) {
    let n = Ht.get(s);
    n || Ht.set(s, n = []), n.push(e);
  }
}
function Rr(e, t, s = U) {
  const { immediate: n, deep: i, once: r, scheduler: o, augmentJob: l, call: f } = s, h = (P) => i ? P : ae(P) || i === !1 || i === 0 ? Ne(P, 1) : Ne(P);
  let a, p, C, S, M = !1, F = !1;
  if (X(e) ? (p = () => e.value, M = ae(e)) : Xe(e) ? (p = () => h(e), M = !0) : O(e) ? (F = !0, M = e.some((P) => Xe(P) || ae(P)), p = () => e.map((P) => {
    if (X(P))
      return P.value;
    if (Xe(P))
      return h(P);
    if (R(P))
      return f ? f(P, 2) : P();
  })) : R(e) ? t ? p = f ? () => f(e, 2) : e : p = () => {
    if (C) {
      je();
      try {
        C();
      } finally {
        He();
      }
    }
    const P = We;
    We = a;
    try {
      return f ? f(e, 3, [S]) : e(S);
    } finally {
      We = P;
    }
  } : p = Ce, t && i) {
    const P = p, J = i === !0 ? 1 / 0 : i;
    p = () => Ne(P(), J);
  }
  const Z = sr(), j = () => {
    a.stop(), Z && Rs(Z.effects, a);
  };
  if (r && t) {
    const P = t;
    t = (...J) => {
      P(...J), j();
    };
  }
  let W = F ? new Array(e.length).fill(It) : It;
  const q = (P) => {
    if (!(!(a.flags & 1) || !a.dirty && !P))
      if (t) {
        const J = a.run();
        if (i || M || (F ? J.some((Re, ge) => De(Re, W[ge])) : De(J, W))) {
          C && C();
          const Re = We;
          We = a;
          try {
            const ge = [
              J,
              // pass undefined as the old value when it's changed for the first time
              W === It ? void 0 : F && W[0] === It ? [] : W,
              S
            ];
            f ? f(t, 3, ge) : (
              // @ts-expect-error
              t(...ge)
            ), W = J;
          } finally {
            We = Re;
          }
        }
      } else
        a.run();
  };
  return l && l(q), a = new Bn(p), a.scheduler = o ? () => o(q, !1) : q, S = (P) => Or(P, !1, a), C = a.onStop = () => {
    const P = Ht.get(a);
    if (P) {
      if (f)
        f(P, 4);
      else
        for (const J of P) J();
      Ht.delete(a);
    }
  }, t ? n ? q(!0) : W = a.run() : o ? o(q.bind(null, !0), !0) : a.run(), j.pause = a.pause.bind(a), j.resume = a.resume.bind(a), j.stop = j, j;
}
function Ne(e, t = 1 / 0, s) {
  if (t <= 0 || !B(e) || e.__v_skip || (s = s || /* @__PURE__ */ new Set(), s.has(e)))
    return e;
  if (s.add(e), t--, X(e))
    Ne(e.value, t, s);
  else if (O(e))
    for (let n = 0; n < e.length; n++)
      Ne(e[n], t, s);
  else if (Nn(e) || Qe(e))
    e.forEach((n) => {
      Ne(n, t, s);
    });
  else if (qt(e)) {
    for (const n in e)
      Ne(e[n], t, s);
    for (const n of Object.getOwnPropertySymbols(e))
      Object.prototype.propertyIsEnumerable.call(e, n) && Ne(e[n], t, s);
  }
  return e;
}
/**
* @vue/runtime-core v3.5.12
* (c) 2018-present Yuxi (Evan) You and Vue contributors
* @license MIT
**/
function Ct(e, t, s, n) {
  try {
    return n ? e(...n) : e();
  } catch (i) {
    zt(i, t, s);
  }
}
function Se(e, t, s, n) {
  if (R(e)) {
    const i = Ct(e, t, s, n);
    return i && Dn(i) && i.catch((r) => {
      zt(r, t, s);
    }), i;
  }
  if (O(e)) {
    const i = [];
    for (let r = 0; r < e.length; r++)
      i.push(Se(e[r], t, s, n));
    return i;
  }
}
function zt(e, t, s, n = !0) {
  const i = t ? t.vnode : null, { errorHandler: r, throwUnhandledErrorInProduction: o } = t && t.appContext.config || U;
  if (t) {
    let l = t.parent;
    const f = t.proxy, h = `https://vuejs.org/error-reference/#runtime-${s}`;
    for (; l; ) {
      const a = l.ec;
      if (a) {
        for (let p = 0; p < a.length; p++)
          if (a[p](e, f, h) === !1)
            return;
      }
      l = l.parent;
    }
    if (r) {
      je(), Ct(r, null, 10, [
        e,
        f,
        h
      ]), He();
      return;
    }
  }
  Mr(e, s, i, n, o);
}
function Mr(e, t, s, n = !0, i = !1) {
  if (i)
    throw e;
  console.error(e);
}
const se = [];
let ye = -1;
const Ze = [];
let Ie = null, Je = 0;
const li = /* @__PURE__ */ Promise.resolve();
let Lt = null;
function ci(e) {
  const t = Lt || li;
  return e ? t.then(this ? e.bind(this) : e) : t;
}
function Ir(e) {
  let t = ye + 1, s = se.length;
  for (; t < s; ) {
    const n = t + s >>> 1, i = se[n], r = yt(i);
    r < e || r === e && i.flags & 2 ? t = n + 1 : s = n;
  }
  return t;
}
function Us(e) {
  if (!(e.flags & 1)) {
    const t = yt(e), s = se[se.length - 1];
    !s || // fast path when the job id is larger than the tail
    !(e.flags & 2) && t >= yt(s) ? se.push(e) : se.splice(Ir(t), 0, e), e.flags |= 1, fi();
  }
}
function fi() {
  Lt || (Lt = li.then(ai));
}
function Fr(e) {
  O(e) ? Ze.push(...e) : Ie && e.id === -1 ? Ie.splice(Je + 1, 0, e) : e.flags & 1 || (Ze.push(e), e.flags |= 1), fi();
}
function cn(e, t, s = ye + 1) {
  for (; s < se.length; s++) {
    const n = se[s];
    if (n && n.flags & 2) {
      if (e && n.id !== e.uid)
        continue;
      se.splice(s, 1), s--, n.flags & 4 && (n.flags &= -2), n(), n.flags & 4 || (n.flags &= -2);
    }
  }
}
function ui(e) {
  if (Ze.length) {
    const t = [...new Set(Ze)].sort(
      (s, n) => yt(s) - yt(n)
    );
    if (Ze.length = 0, Ie) {
      Ie.push(...t);
      return;
    }
    for (Ie = t, Je = 0; Je < Ie.length; Je++) {
      const s = Ie[Je];
      s.flags & 4 && (s.flags &= -2), s.flags & 8 || s(), s.flags &= -2;
    }
    Ie = null, Je = 0;
  }
}
const yt = (e) => e.id == null ? e.flags & 2 ? -1 : 1 / 0 : e.id;
function ai(e) {
  try {
    for (ye = 0; ye < se.length; ye++) {
      const t = se[ye];
      t && !(t.flags & 8) && (t.flags & 4 && (t.flags &= -2), Ct(
        t,
        t.i,
        t.i ? 15 : 14
      ), t.flags & 4 || (t.flags &= -2));
    }
  } finally {
    for (; ye < se.length; ye++) {
      const t = se[ye];
      t && (t.flags &= -2);
    }
    ye = -1, se.length = 0, ui(), Lt = null, (se.length || Ze.length) && ai();
  }
}
let we = null, hi = null;
function Kt(e) {
  const t = we;
  return we = e, hi = e && e.type.__scopeId || null, t;
}
function Nr(e, t = we, s) {
  if (!t || e._n)
    return e;
  const n = (...i) => {
    n._d && _n(-1);
    const r = Kt(t);
    let o;
    try {
      o = e(...i);
    } finally {
      Kt(r), n._d && _n(1);
    }
    return o;
  };
  return n._n = !0, n._c = !0, n._d = !0, n;
}
function Ve(e, t, s, n) {
  const i = e.dirs, r = t && t.dirs;
  for (let o = 0; o < i.length; o++) {
    const l = i[o];
    r && (l.oldValue = r[o].value);
    let f = l.dir[n];
    f && (je(), Se(f, s, 8, [
      e.el,
      l,
      e,
      t
    ]), He());
  }
}
const Dr = Symbol("_vte"), $r = (e) => e.__isTeleport;
function Vs(e, t) {
  e.shapeFlag & 6 && e.component ? (e.transition = t, Vs(e.component.subTree, t)) : e.shapeFlag & 128 ? (e.ssContent.transition = t.clone(e.ssContent), e.ssFallback.transition = t.clone(e.ssFallback)) : e.transition = t;
}
/*! #__NO_SIDE_EFFECTS__ */
// @__NO_SIDE_EFFECTS__
function Bs(e, t) {
  return R(e) ? (
    // #8236: extend call and options.name access are considered side-effects
    // by Rollup, so we have to wrap it in a pure-annotated IIFE.
    k({ name: e.name }, t, { setup: e })
  ) : e;
}
function di(e) {
  e.ids = [e.ids[0] + e.ids[2]++ + "-", 0, 0];
}
function xs(e, t, s, n, i = !1) {
  if (O(e)) {
    e.forEach(
      (M, F) => xs(
        M,
        t && (O(t) ? t[F] : t),
        s,
        n,
        i
      )
    );
    return;
  }
  if (ht(n) && !i)
    return;
  const r = n.shapeFlag & 4 ? Js(n.component) : n.el, o = i ? null : r, { i: l, r: f } = e, h = t && t.r, a = l.refs === U ? l.refs = {} : l.refs, p = l.setupState, C = D(p), S = p === U ? () => !1 : (M) => N(C, M);
  if (h != null && h !== f && (G(h) ? (a[h] = null, S(h) && (p[h] = null)) : X(h) && (h.value = null)), R(f))
    Ct(f, l, 12, [o, a]);
  else {
    const M = G(f), F = X(f);
    if (M || F) {
      const Z = () => {
        if (e.f) {
          const j = M ? S(f) ? p[f] : a[f] : f.value;
          i ? O(j) && Rs(j, r) : O(j) ? j.includes(r) || j.push(r) : M ? (a[f] = [r], S(f) && (p[f] = a[f])) : (f.value = [r], e.k && (a[e.k] = f.value));
        } else M ? (a[f] = o, S(f) && (p[f] = o)) : F && (f.value = o, e.k && (a[e.k] = o));
      };
      o ? (Z.id = -1, le(Z, s)) : Z();
    }
  }
}
Gt().requestIdleCallback;
Gt().cancelIdleCallback;
const ht = (e) => !!e.type.__asyncLoader, pi = (e) => e.type.__isKeepAlive;
function jr(e, t) {
  gi(e, "a", t);
}
function Hr(e, t) {
  gi(e, "da", t);
}
function gi(e, t, s = ne) {
  const n = e.__wdc || (e.__wdc = () => {
    let i = s;
    for (; i; ) {
      if (i.isDeactivated)
        return;
      i = i.parent;
    }
    return e();
  });
  if (Yt(t, n, s), s) {
    let i = s.parent;
    for (; i && i.parent; )
      pi(i.parent.vnode) && Lr(n, t, s, i), i = i.parent;
  }
}
function Lr(e, t, s, n) {
  const i = Yt(
    t,
    e,
    n,
    !0
    /* prepend */
  );
  _i(() => {
    Rs(n[t], i);
  }, s);
}
function Yt(e, t, s = ne, n = !1) {
  if (s) {
    const i = s[e] || (s[e] = []), r = t.__weh || (t.__weh = (...o) => {
      je();
      const l = St(s), f = Se(t, s, e, o);
      return l(), He(), f;
    });
    return n ? i.unshift(r) : i.push(r), r;
  }
}
const Oe = (e) => (t, s = ne) => {
  (!wt || e === "sp") && Yt(e, (...n) => t(...n), s);
}, Kr = Oe("bm"), Ur = Oe("m"), Vr = Oe(
  "bu"
), Br = Oe("u"), Wr = Oe(
  "bum"
), _i = Oe("um"), qr = Oe(
  "sp"
), kr = Oe("rtg"), Gr = Oe("rtc");
function Jr(e, t = ne) {
  Yt("ec", e, t);
}
const zr = Symbol.for("v-ndc");
function Yr(e, t, s, n) {
  let i;
  const r = s, o = O(e);
  if (o || G(e)) {
    const l = o && Xe(e);
    let f = !1;
    l && (f = !ae(e), e = Jt(e)), i = new Array(e.length);
    for (let h = 0, a = e.length; h < a; h++)
      i[h] = t(
        f ? Q(e[h]) : e[h],
        h,
        void 0,
        r
      );
  } else if (typeof e == "number") {
    i = new Array(e);
    for (let l = 0; l < e; l++)
      i[l] = t(l + 1, l, void 0, r);
  } else if (B(e))
    if (e[Symbol.iterator])
      i = Array.from(
        e,
        (l, f) => t(l, f, void 0, r)
      );
    else {
      const l = Object.keys(e);
      i = new Array(l.length);
      for (let f = 0, h = l.length; f < h; f++) {
        const a = l[f];
        i[f] = t(e[a], a, f, r);
      }
    }
  else
    i = [];
  return i;
}
const vs = (e) => e ? ji(e) ? Js(e) : vs(e.parent) : null, dt = (
  // Move PURE marker to new line to workaround compiler discarding it
  // due to type annotation
  /* @__PURE__ */ k(/* @__PURE__ */ Object.create(null), {
    $: (e) => e,
    $el: (e) => e.vnode.el,
    $data: (e) => e.data,
    $props: (e) => e.props,
    $attrs: (e) => e.attrs,
    $slots: (e) => e.slots,
    $refs: (e) => e.refs,
    $parent: (e) => vs(e.parent),
    $root: (e) => vs(e.root),
    $host: (e) => e.ce,
    $emit: (e) => e.emit,
    $options: (e) => Ws(e),
    $forceUpdate: (e) => e.f || (e.f = () => {
      Us(e.update);
    }),
    $nextTick: (e) => e.n || (e.n = ci.bind(e.proxy)),
    $watch: (e) => xo.bind(e)
  })
), ls = (e, t) => e !== U && !e.__isScriptSetup && N(e, t), Qr = {
  get({ _: e }, t) {
    if (t === "__v_skip")
      return !0;
    const { ctx: s, setupState: n, data: i, props: r, accessCache: o, type: l, appContext: f } = e;
    let h;
    if (t[0] !== "$") {
      const S = o[t];
      if (S !== void 0)
        switch (S) {
          case 1:
            return n[t];
          case 2:
            return i[t];
          case 4:
            return s[t];
          case 3:
            return r[t];
        }
      else {
        if (ls(n, t))
          return o[t] = 1, n[t];
        if (i !== U && N(i, t))
          return o[t] = 2, i[t];
        if (
          // only cache other properties when instance has declared (thus stable)
          // props
          (h = e.propsOptions[0]) && N(h, t)
        )
          return o[t] = 3, r[t];
        if (s !== U && N(s, t))
          return o[t] = 4, s[t];
        ws && (o[t] = 0);
      }
    }
    const a = dt[t];
    let p, C;
    if (a)
      return t === "$attrs" && Y(e.attrs, "get", ""), a(e);
    if (
      // css module (injected by vue-loader)
      (p = l.__cssModules) && (p = p[t])
    )
      return p;
    if (s !== U && N(s, t))
      return o[t] = 4, s[t];
    if (
      // global properties
      C = f.config.globalProperties, N(C, t)
    )
      return C[t];
  },
  set({ _: e }, t, s) {
    const { data: n, setupState: i, ctx: r } = e;
    return ls(i, t) ? (i[t] = s, !0) : n !== U && N(n, t) ? (n[t] = s, !0) : N(e.props, t) || t[0] === "$" && t.slice(1) in e ? !1 : (r[t] = s, !0);
  },
  has({
    _: { data: e, setupState: t, accessCache: s, ctx: n, appContext: i, propsOptions: r }
  }, o) {
    let l;
    return !!s[o] || e !== U && N(e, o) || ls(t, o) || (l = r[0]) && N(l, o) || N(n, o) || N(dt, o) || N(i.config.globalProperties, o);
  },
  defineProperty(e, t, s) {
    return s.get != null ? e._.accessCache[t] = 0 : N(s, "value") && this.set(e, t, s.value, null), Reflect.defineProperty(e, t, s);
  }
};
function fn(e) {
  return O(e) ? e.reduce(
    (t, s) => (t[s] = null, t),
    {}
  ) : e;
}
let ws = !0;
function Xr(e) {
  const t = Ws(e), s = e.proxy, n = e.ctx;
  ws = !1, t.beforeCreate && un(t.beforeCreate, e, "bc");
  const {
    // state
    data: i,
    computed: r,
    methods: o,
    watch: l,
    provide: f,
    inject: h,
    // lifecycle
    created: a,
    beforeMount: p,
    mounted: C,
    beforeUpdate: S,
    updated: M,
    activated: F,
    deactivated: Z,
    beforeDestroy: j,
    beforeUnmount: W,
    destroyed: q,
    unmounted: P,
    render: J,
    renderTracked: Re,
    renderTriggered: ge,
    errorCaptured: Me,
    serverPrefetch: Tt,
    // public API
    expose: Le,
    inheritAttrs: st,
    // assets
    components: Et,
    directives: Pt,
    filters: Zt
  } = t;
  if (h && Zr(h, n, null), o)
    for (const V in o) {
      const H = o[V];
      R(H) && (n[V] = H.bind(s));
    }
  if (i) {
    const V = i.call(s, s);
    B(V) && (e.data = Hs(V));
  }
  if (ws = !0, r)
    for (const V in r) {
      const H = r[V], Ke = R(H) ? H.bind(s, s) : R(H.get) ? H.get.bind(s, s) : Ce, At = !R(H) && R(H.set) ? H.set.bind(s) : Ce, Ue = ze({
        get: Ke,
        set: At
      });
      Object.defineProperty(n, V, {
        enumerable: !0,
        configurable: !0,
        get: () => Ue.value,
        set: (_e) => Ue.value = _e
      });
    }
  if (l)
    for (const V in l)
      mi(l[V], n, s, V);
  if (f) {
    const V = R(f) ? f.call(s) : f;
    Reflect.ownKeys(V).forEach((H) => {
      ro(H, V[H]);
    });
  }
  a && un(a, e, "c");
  function ee(V, H) {
    O(H) ? H.forEach((Ke) => V(Ke.bind(s))) : H && V(H.bind(s));
  }
  if (ee(Kr, p), ee(Ur, C), ee(Vr, S), ee(Br, M), ee(jr, F), ee(Hr, Z), ee(Jr, Me), ee(Gr, Re), ee(kr, ge), ee(Wr, W), ee(_i, P), ee(qr, Tt), O(Le))
    if (Le.length) {
      const V = e.exposed || (e.exposed = {});
      Le.forEach((H) => {
        Object.defineProperty(V, H, {
          get: () => s[H],
          set: (Ke) => s[H] = Ke
        });
      });
    } else e.exposed || (e.exposed = {});
  J && e.render === Ce && (e.render = J), st != null && (e.inheritAttrs = st), Et && (e.components = Et), Pt && (e.directives = Pt), Tt && di(e);
}
function Zr(e, t, s = Ce) {
  O(e) && (e = Cs(e));
  for (const n in e) {
    const i = e[n];
    let r;
    B(i) ? "default" in i ? r = Nt(
      i.from || n,
      i.default,
      !0
    ) : r = Nt(i.from || n) : r = Nt(i), X(r) ? Object.defineProperty(t, n, {
      enumerable: !0,
      configurable: !0,
      get: () => r.value,
      set: (o) => r.value = o
    }) : t[n] = r;
  }
}
function un(e, t, s) {
  Se(
    O(e) ? e.map((n) => n.bind(t.proxy)) : e.bind(t.proxy),
    t,
    s
  );
}
function mi(e, t, s, n) {
  let i = n.includes(".") ? Ri(s, n) : () => s[n];
  if (G(e)) {
    const r = t[e];
    R(r) && fs(i, r);
  } else if (R(e))
    fs(i, e.bind(s));
  else if (B(e))
    if (O(e))
      e.forEach((r) => mi(r, t, s, n));
    else {
      const r = R(e.handler) ? e.handler.bind(s) : t[e.handler];
      R(r) && fs(i, r, e);
    }
}
function Ws(e) {
  const t = e.type, { mixins: s, extends: n } = t, {
    mixins: i,
    optionsCache: r,
    config: { optionMergeStrategies: o }
  } = e.appContext, l = r.get(t);
  let f;
  return l ? f = l : !i.length && !s && !n ? f = t : (f = {}, i.length && i.forEach(
    (h) => Ut(f, h, o, !0)
  ), Ut(f, t, o)), B(t) && r.set(t, f), f;
}
function Ut(e, t, s, n = !1) {
  const { mixins: i, extends: r } = t;
  r && Ut(e, r, s, !0), i && i.forEach(
    (o) => Ut(e, o, s, !0)
  );
  for (const o in t)
    if (!(n && o === "expose")) {
      const l = eo[o] || s && s[o];
      e[o] = l ? l(e[o], t[o]) : t[o];
    }
  return e;
}
const eo = {
  data: an,
  props: hn,
  emits: hn,
  // objects
  methods: ct,
  computed: ct,
  // lifecycle
  beforeCreate: te,
  created: te,
  beforeMount: te,
  mounted: te,
  beforeUpdate: te,
  updated: te,
  beforeDestroy: te,
  beforeUnmount: te,
  destroyed: te,
  unmounted: te,
  activated: te,
  deactivated: te,
  errorCaptured: te,
  serverPrefetch: te,
  // assets
  components: ct,
  directives: ct,
  // watch
  watch: so,
  // provide / inject
  provide: an,
  inject: to
};
function an(e, t) {
  return t ? e ? function() {
    return k(
      R(e) ? e.call(this, this) : e,
      R(t) ? t.call(this, this) : t
    );
  } : t : e;
}
function to(e, t) {
  return ct(Cs(e), Cs(t));
}
function Cs(e) {
  if (O(e)) {
    const t = {};
    for (let s = 0; s < e.length; s++)
      t[e[s]] = e[s];
    return t;
  }
  return e;
}
function te(e, t) {
  return e ? [...new Set([].concat(e, t))] : t;
}
function ct(e, t) {
  return e ? k(/* @__PURE__ */ Object.create(null), e, t) : t;
}
function hn(e, t) {
  return e ? O(e) && O(t) ? [.../* @__PURE__ */ new Set([...e, ...t])] : k(
    /* @__PURE__ */ Object.create(null),
    fn(e),
    fn(t ?? {})
  ) : t;
}
function so(e, t) {
  if (!e) return t;
  if (!t) return e;
  const s = k(/* @__PURE__ */ Object.create(null), e);
  for (const n in t)
    s[n] = te(e[n], t[n]);
  return s;
}
function bi() {
  return {
    app: null,
    config: {
      isNativeTag: Bi,
      performance: !1,
      globalProperties: {},
      optionMergeStrategies: {},
      errorHandler: void 0,
      warnHandler: void 0,
      compilerOptions: {}
    },
    mixins: [],
    components: {},
    directives: {},
    provides: /* @__PURE__ */ Object.create(null),
    optionsCache: /* @__PURE__ */ new WeakMap(),
    propsCache: /* @__PURE__ */ new WeakMap(),
    emitsCache: /* @__PURE__ */ new WeakMap()
  };
}
let no = 0;
function io(e, t) {
  return function(n, i = null) {
    R(n) || (n = k({}, n)), i != null && !B(i) && (i = null);
    const r = bi(), o = /* @__PURE__ */ new WeakSet(), l = [];
    let f = !1;
    const h = r.app = {
      _uid: no++,
      _component: n,
      _props: i,
      _container: null,
      _context: r,
      _instance: null,
      version: Uo,
      get config() {
        return r.config;
      },
      set config(a) {
      },
      use(a, ...p) {
        return o.has(a) || (a && R(a.install) ? (o.add(a), a.install(h, ...p)) : R(a) && (o.add(a), a(h, ...p))), h;
      },
      mixin(a) {
        return r.mixins.includes(a) || r.mixins.push(a), h;
      },
      component(a, p) {
        return p ? (r.components[a] = p, h) : r.components[a];
      },
      directive(a, p) {
        return p ? (r.directives[a] = p, h) : r.directives[a];
      },
      mount(a, p, C) {
        if (!f) {
          const S = h._ceVNode || Ae(n, i);
          return S.appContext = r, C === !0 ? C = "svg" : C === !1 && (C = void 0), p && t ? t(S, a) : e(S, a, C), f = !0, h._container = a, a.__vue_app__ = h, Js(S.component);
        }
      },
      onUnmount(a) {
        l.push(a);
      },
      unmount() {
        f && (Se(
          l,
          h._instance,
          16
        ), e(null, h._container), delete h._container.__vue_app__);
      },
      provide(a, p) {
        return r.provides[a] = p, h;
      },
      runWithContext(a) {
        const p = et;
        et = h;
        try {
          return a();
        } finally {
          et = p;
        }
      }
    };
    return h;
  };
}
let et = null;
function ro(e, t) {
  if (ne) {
    let s = ne.provides;
    const n = ne.parent && ne.parent.provides;
    n === s && (s = ne.provides = Object.create(n)), s[e] = t;
  }
}
function Nt(e, t, s = !1) {
  const n = ne || we;
  if (n || et) {
    const i = et ? et._context.provides : n ? n.parent == null ? n.vnode.appContext && n.vnode.appContext.provides : n.parent.provides : void 0;
    if (i && e in i)
      return i[e];
    if (arguments.length > 1)
      return s && R(t) ? t.call(n && n.proxy) : t;
  }
}
const yi = {}, xi = () => Object.create(yi), vi = (e) => Object.getPrototypeOf(e) === yi;
function oo(e, t, s, n = !1) {
  const i = {}, r = xi();
  e.propsDefaults = /* @__PURE__ */ Object.create(null), wi(e, t, i, r);
  for (const o in e.propsOptions[0])
    o in i || (i[o] = void 0);
  s ? e.props = n ? i : vr(i) : e.type.props ? e.props = i : e.props = r, e.attrs = r;
}
function lo(e, t, s, n) {
  const {
    props: i,
    attrs: r,
    vnode: { patchFlag: o }
  } = e, l = D(i), [f] = e.propsOptions;
  let h = !1;
  if (
    // always force full diff in dev
    // - #1942 if hmr is enabled with sfc component
    // - vite#872 non-sfc component used by sfc component
    (n || o > 0) && !(o & 16)
  ) {
    if (o & 8) {
      const a = e.vnode.dynamicProps;
      for (let p = 0; p < a.length; p++) {
        let C = a[p];
        if (Qt(e.emitsOptions, C))
          continue;
        const S = t[C];
        if (f)
          if (N(r, C))
            S !== r[C] && (r[C] = S, h = !0);
          else {
            const M = de(C);
            i[M] = Ss(
              f,
              l,
              M,
              S,
              e,
              !1
            );
          }
        else
          S !== r[C] && (r[C] = S, h = !0);
      }
    }
  } else {
    wi(e, t, i, r) && (h = !0);
    let a;
    for (const p in l)
      (!t || // for camelCase
      !N(t, p) && // it's possible the original props was passed in as kebab-case
      // and converted to camelCase (#955)
      ((a = fe(p)) === p || !N(t, a))) && (f ? s && // for camelCase
      (s[p] !== void 0 || // for kebab-case
      s[a] !== void 0) && (i[p] = Ss(
        f,
        l,
        p,
        void 0,
        e,
        !0
      )) : delete i[p]);
    if (r !== l)
      for (const p in r)
        (!t || !N(t, p)) && (delete r[p], h = !0);
  }
  h && Pe(e.attrs, "set", "");
}
function wi(e, t, s, n) {
  const [i, r] = e.propsOptions;
  let o = !1, l;
  if (t)
    for (let f in t) {
      if (ft(f))
        continue;
      const h = t[f];
      let a;
      i && N(i, a = de(f)) ? !r || !r.includes(a) ? s[a] = h : (l || (l = {}))[a] = h : Qt(e.emitsOptions, f) || (!(f in n) || h !== n[f]) && (n[f] = h, o = !0);
    }
  if (r) {
    const f = D(s), h = l || U;
    for (let a = 0; a < r.length; a++) {
      const p = r[a];
      s[p] = Ss(
        i,
        f,
        p,
        h[p],
        e,
        !N(h, p)
      );
    }
  }
  return o;
}
function Ss(e, t, s, n, i, r) {
  const o = e[s];
  if (o != null) {
    const l = N(o, "default");
    if (l && n === void 0) {
      const f = o.default;
      if (o.type !== Function && !o.skipFactory && R(f)) {
        const { propsDefaults: h } = i;
        if (s in h)
          n = h[s];
        else {
          const a = St(i);
          n = h[s] = f.call(
            null,
            t
          ), a();
        }
      } else
        n = f;
      i.ce && i.ce._setProp(s, n);
    }
    o[
      0
      /* shouldCast */
    ] && (r && !l ? n = !1 : o[
      1
      /* shouldCastTrue */
    ] && (n === "" || n === fe(s)) && (n = !0));
  }
  return n;
}
const co = /* @__PURE__ */ new WeakMap();
function Ci(e, t, s = !1) {
  const n = s ? co : t.propsCache, i = n.get(e);
  if (i)
    return i;
  const r = e.props, o = {}, l = [];
  let f = !1;
  if (!R(e)) {
    const a = (p) => {
      f = !0;
      const [C, S] = Ci(p, t, !0);
      k(o, C), S && l.push(...S);
    };
    !s && t.mixins.length && t.mixins.forEach(a), e.extends && a(e.extends), e.mixins && e.mixins.forEach(a);
  }
  if (!r && !f)
    return B(e) && n.set(e, Ye), Ye;
  if (O(r))
    for (let a = 0; a < r.length; a++) {
      const p = de(r[a]);
      dn(p) && (o[p] = U);
    }
  else if (r)
    for (const a in r) {
      const p = de(a);
      if (dn(p)) {
        const C = r[a], S = o[p] = O(C) || R(C) ? { type: C } : k({}, C), M = S.type;
        let F = !1, Z = !0;
        if (O(M))
          for (let j = 0; j < M.length; ++j) {
            const W = M[j], q = R(W) && W.name;
            if (q === "Boolean") {
              F = !0;
              break;
            } else q === "String" && (Z = !1);
          }
        else
          F = R(M) && M.name === "Boolean";
        S[
          0
          /* shouldCast */
        ] = F, S[
          1
          /* shouldCastTrue */
        ] = Z, (F || N(S, "default")) && l.push(p);
      }
    }
  const h = [o, l];
  return B(e) && n.set(e, h), h;
}
function dn(e) {
  return e[0] !== "$" && !ft(e);
}
const Si = (e) => e[0] === "_" || e === "$stable", qs = (e) => O(e) ? e.map(ve) : [ve(e)], fo = (e, t, s) => {
  if (t._n)
    return t;
  const n = Nr((...i) => qs(t(...i)), s);
  return n._c = !1, n;
}, Ti = (e, t, s) => {
  const n = e._ctx;
  for (const i in e) {
    if (Si(i)) continue;
    const r = e[i];
    if (R(r))
      t[i] = fo(i, r, n);
    else if (r != null) {
      const o = qs(r);
      t[i] = () => o;
    }
  }
}, Ei = (e, t) => {
  const s = qs(t);
  e.slots.default = () => s;
}, Pi = (e, t, s) => {
  for (const n in t)
    (s || n !== "_") && (e[n] = t[n]);
}, uo = (e, t, s) => {
  const n = e.slots = xi();
  if (e.vnode.shapeFlag & 32) {
    const i = t._;
    i ? (Pi(n, t, s), s && Hn(n, "_", i, !0)) : Ti(t, n);
  } else t && Ei(e, t);
}, ao = (e, t, s) => {
  const { vnode: n, slots: i } = e;
  let r = !0, o = U;
  if (n.shapeFlag & 32) {
    const l = t._;
    l ? s && l === 1 ? r = !1 : Pi(i, t, s) : (r = !t.$stable, Ti(t, i)), o = t;
  } else t && (Ei(e, t), o = { default: 1 });
  if (r)
    for (const l in i)
      !Si(l) && o[l] == null && delete i[l];
}, le = Po;
function ho(e) {
  return po(e);
}
function po(e, t) {
  const s = Gt();
  s.__VUE__ = !0;
  const {
    insert: n,
    remove: i,
    patchProp: r,
    createElement: o,
    createText: l,
    createComment: f,
    setText: h,
    setElementText: a,
    parentNode: p,
    nextSibling: C,
    setScopeId: S = Ce,
    insertStaticContent: M
  } = e, F = (c, u, d, m = null, g = null, _ = null, v = void 0, x = null, y = !!u.dynamicChildren) => {
    if (c === u)
      return;
    c && !lt(c, u) && (m = Ot(c), _e(c, g, _, !0), c = null), u.patchFlag === -2 && (y = !1, u.dynamicChildren = null);
    const { type: b, ref: E, shapeFlag: w } = u;
    switch (b) {
      case Xt:
        Z(c, u, d, m);
        break;
      case xt:
        j(c, u, d, m);
        break;
      case as:
        c == null && W(u, d, m, v);
        break;
      case xe:
        Et(
          c,
          u,
          d,
          m,
          g,
          _,
          v,
          x,
          y
        );
        break;
      default:
        w & 1 ? J(
          c,
          u,
          d,
          m,
          g,
          _,
          v,
          x,
          y
        ) : w & 6 ? Pt(
          c,
          u,
          d,
          m,
          g,
          _,
          v,
          x,
          y
        ) : (w & 64 || w & 128) && b.process(
          c,
          u,
          d,
          m,
          g,
          _,
          v,
          x,
          y,
          it
        );
    }
    E != null && g && xs(E, c && c.ref, _, u || c, !u);
  }, Z = (c, u, d, m) => {
    if (c == null)
      n(
        u.el = l(u.children),
        d,
        m
      );
    else {
      const g = u.el = c.el;
      u.children !== c.children && h(g, u.children);
    }
  }, j = (c, u, d, m) => {
    c == null ? n(
      u.el = f(u.children || ""),
      d,
      m
    ) : u.el = c.el;
  }, W = (c, u, d, m) => {
    [c.el, c.anchor] = M(
      c.children,
      u,
      d,
      m,
      c.el,
      c.anchor
    );
  }, q = ({ el: c, anchor: u }, d, m) => {
    let g;
    for (; c && c !== u; )
      g = C(c), n(c, d, m), c = g;
    n(u, d, m);
  }, P = ({ el: c, anchor: u }) => {
    let d;
    for (; c && c !== u; )
      d = C(c), i(c), c = d;
    i(u);
  }, J = (c, u, d, m, g, _, v, x, y) => {
    u.type === "svg" ? v = "svg" : u.type === "math" && (v = "mathml"), c == null ? Re(
      u,
      d,
      m,
      g,
      _,
      v,
      x,
      y
    ) : Tt(
      c,
      u,
      g,
      _,
      v,
      x,
      y
    );
  }, Re = (c, u, d, m, g, _, v, x) => {
    let y, b;
    const { props: E, shapeFlag: w, transition: T, dirs: A } = c;
    if (y = c.el = o(
      c.type,
      _,
      E && E.is,
      E
    ), w & 8 ? a(y, c.children) : w & 16 && Me(
      c.children,
      y,
      null,
      m,
      g,
      cs(c, _),
      v,
      x
    ), A && Ve(c, null, m, "created"), ge(y, c, c.scopeId, v, m), E) {
      for (const L in E)
        L !== "value" && !ft(L) && r(y, L, null, E[L], _, m);
      "value" in E && r(y, "value", null, E.value, _), (b = E.onVnodeBeforeMount) && be(b, m, c);
    }
    A && Ve(c, null, m, "beforeMount");
    const I = go(g, T);
    I && T.beforeEnter(y), n(y, u, d), ((b = E && E.onVnodeMounted) || I || A) && le(() => {
      b && be(b, m, c), I && T.enter(y), A && Ve(c, null, m, "mounted");
    }, g);
  }, ge = (c, u, d, m, g) => {
    if (d && S(c, d), m)
      for (let _ = 0; _ < m.length; _++)
        S(c, m[_]);
    if (g) {
      let _ = g.subTree;
      if (u === _ || Ii(_.type) && (_.ssContent === u || _.ssFallback === u)) {
        const v = g.vnode;
        ge(
          c,
          v,
          v.scopeId,
          v.slotScopeIds,
          g.parent
        );
      }
    }
  }, Me = (c, u, d, m, g, _, v, x, y = 0) => {
    for (let b = y; b < c.length; b++) {
      const E = c[b] = x ? Fe(c[b]) : ve(c[b]);
      F(
        null,
        E,
        u,
        d,
        m,
        g,
        _,
        v,
        x
      );
    }
  }, Tt = (c, u, d, m, g, _, v) => {
    const x = u.el = c.el;
    let { patchFlag: y, dynamicChildren: b, dirs: E } = u;
    y |= c.patchFlag & 16;
    const w = c.props || U, T = u.props || U;
    let A;
    if (d && Be(d, !1), (A = T.onVnodeBeforeUpdate) && be(A, d, u, c), E && Ve(u, c, d, "beforeUpdate"), d && Be(d, !0), (w.innerHTML && T.innerHTML == null || w.textContent && T.textContent == null) && a(x, ""), b ? Le(
      c.dynamicChildren,
      b,
      x,
      d,
      m,
      cs(u, g),
      _
    ) : v || H(
      c,
      u,
      x,
      null,
      d,
      m,
      cs(u, g),
      _,
      !1
    ), y > 0) {
      if (y & 16)
        st(x, w, T, d, g);
      else if (y & 2 && w.class !== T.class && r(x, "class", null, T.class, g), y & 4 && r(x, "style", w.style, T.style, g), y & 8) {
        const I = u.dynamicProps;
        for (let L = 0; L < I.length; L++) {
          const $ = I[L], ie = w[$], z = T[$];
          (z !== ie || $ === "value") && r(x, $, ie, z, g, d);
        }
      }
      y & 1 && c.children !== u.children && a(x, u.children);
    } else !v && b == null && st(x, w, T, d, g);
    ((A = T.onVnodeUpdated) || E) && le(() => {
      A && be(A, d, u, c), E && Ve(u, c, d, "updated");
    }, m);
  }, Le = (c, u, d, m, g, _, v) => {
    for (let x = 0; x < u.length; x++) {
      const y = c[x], b = u[x], E = (
        // oldVNode may be an errored async setup() component inside Suspense
        // which will not have a mounted element
        y.el && // - In the case of a Fragment, we need to provide the actual parent
        // of the Fragment itself so it can move its children.
        (y.type === xe || // - In the case of different nodes, there is going to be a replacement
        // which also requires the correct parent container
        !lt(y, b) || // - In the case of a component, it could contain anything.
        y.shapeFlag & 70) ? p(y.el) : (
          // In other cases, the parent container is not actually used so we
          // just pass the block element here to avoid a DOM parentNode call.
          d
        )
      );
      F(
        y,
        b,
        E,
        null,
        m,
        g,
        _,
        v,
        !0
      );
    }
  }, st = (c, u, d, m, g) => {
    if (u !== d) {
      if (u !== U)
        for (const _ in u)
          !ft(_) && !(_ in d) && r(
            c,
            _,
            u[_],
            null,
            g,
            m
          );
      for (const _ in d) {
        if (ft(_)) continue;
        const v = d[_], x = u[_];
        v !== x && _ !== "value" && r(c, _, x, v, g, m);
      }
      "value" in d && r(c, "value", u.value, d.value, g);
    }
  }, Et = (c, u, d, m, g, _, v, x, y) => {
    const b = u.el = c ? c.el : l(""), E = u.anchor = c ? c.anchor : l("");
    let { patchFlag: w, dynamicChildren: T, slotScopeIds: A } = u;
    A && (x = x ? x.concat(A) : A), c == null ? (n(b, d, m), n(E, d, m), Me(
      // #10007
      // such fragment like `<></>` will be compiled into
      // a fragment which doesn't have a children.
      // In this case fallback to an empty array
      u.children || [],
      d,
      E,
      g,
      _,
      v,
      x,
      y
    )) : w > 0 && w & 64 && T && // #2715 the previous fragment could've been a BAILed one as a result
    // of renderSlot() with no valid children
    c.dynamicChildren ? (Le(
      c.dynamicChildren,
      T,
      d,
      g,
      _,
      v,
      x
    ), // #2080 if the stable fragment has a key, it's a <template v-for> that may
    //  get moved around. Make sure all root level vnodes inherit el.
    // #2134 or if it's a component root, it may also get moved around
    // as the component is being moved.
    (u.key != null || g && u === g.subTree) && Ai(
      c,
      u,
      !0
      /* shallow */
    )) : H(
      c,
      u,
      d,
      E,
      g,
      _,
      v,
      x,
      y
    );
  }, Pt = (c, u, d, m, g, _, v, x, y) => {
    u.slotScopeIds = x, c == null ? u.shapeFlag & 512 ? g.ctx.activate(
      u,
      d,
      m,
      v,
      y
    ) : Zt(
      u,
      d,
      m,
      g,
      _,
      v,
      y
    ) : Ys(c, u, y);
  }, Zt = (c, u, d, m, g, _, v) => {
    const x = c.component = Do(
      c,
      m,
      g
    );
    if (pi(c) && (x.ctx.renderer = it), $o(x, !1, v), x.asyncDep) {
      if (g && g.registerDep(x, ee, v), !c.el) {
        const y = x.subTree = Ae(xt);
        j(null, y, u, d);
      }
    } else
      ee(
        x,
        c,
        u,
        d,
        g,
        _,
        v
      );
  }, Ys = (c, u, d) => {
    const m = u.component = c.component;
    if (To(c, u, d))
      if (m.asyncDep && !m.asyncResolved) {
        V(m, u, d);
        return;
      } else
        m.next = u, m.update();
    else
      u.el = c.el, m.vnode = u;
  }, ee = (c, u, d, m, g, _, v) => {
    const x = () => {
      if (c.isMounted) {
        let { next: w, bu: T, u: A, parent: I, vnode: L } = c;
        {
          const re = Oi(c);
          if (re) {
            w && (w.el = L.el, V(c, w, v)), re.asyncDep.then(() => {
              c.isUnmounted || x();
            });
            return;
          }
        }
        let $ = w, ie;
        Be(c, !1), w ? (w.el = L.el, V(c, w, v)) : w = L, T && ss(T), (ie = w.props && w.props.onVnodeBeforeUpdate) && be(ie, I, w, L), Be(c, !0);
        const z = us(c), he = c.subTree;
        c.subTree = z, F(
          he,
          z,
          // parent may have changed if it's in a teleport
          p(he.el),
          // anchor may have changed if it's in a fragment
          Ot(he),
          c,
          g,
          _
        ), w.el = z.el, $ === null && Eo(c, z.el), A && le(A, g), (ie = w.props && w.props.onVnodeUpdated) && le(
          () => be(ie, I, w, L),
          g
        );
      } else {
        let w;
        const { el: T, props: A } = u, { bm: I, m: L, parent: $, root: ie, type: z } = c, he = ht(u);
        if (Be(c, !1), I && ss(I), !he && (w = A && A.onVnodeBeforeMount) && be(w, $, u), Be(c, !0), T && en) {
          const re = () => {
            c.subTree = us(c), en(
              T,
              c.subTree,
              c,
              g,
              null
            );
          };
          he && z.__asyncHydrate ? z.__asyncHydrate(
            T,
            c,
            re
          ) : re();
        } else {
          ie.ce && ie.ce._injectChildStyle(z);
          const re = c.subTree = us(c);
          F(
            null,
            re,
            d,
            m,
            c,
            g,
            _
          ), u.el = re.el;
        }
        if (L && le(L, g), !he && (w = A && A.onVnodeMounted)) {
          const re = u;
          le(
            () => be(w, $, re),
            g
          );
        }
        (u.shapeFlag & 256 || $ && ht($.vnode) && $.vnode.shapeFlag & 256) && c.a && le(c.a, g), c.isMounted = !0, u = d = m = null;
      }
    };
    c.scope.on();
    const y = c.effect = new Bn(x);
    c.scope.off();
    const b = c.update = y.run.bind(y), E = c.job = y.runIfDirty.bind(y);
    E.i = c, E.id = c.uid, y.scheduler = () => Us(E), Be(c, !0), b();
  }, V = (c, u, d) => {
    u.component = c;
    const m = c.vnode.props;
    c.vnode = u, c.next = null, lo(c, u.props, m, d), ao(c, u.children, d), je(), cn(c), He();
  }, H = (c, u, d, m, g, _, v, x, y = !1) => {
    const b = c && c.children, E = c ? c.shapeFlag : 0, w = u.children, { patchFlag: T, shapeFlag: A } = u;
    if (T > 0) {
      if (T & 128) {
        At(
          b,
          w,
          d,
          m,
          g,
          _,
          v,
          x,
          y
        );
        return;
      } else if (T & 256) {
        Ke(
          b,
          w,
          d,
          m,
          g,
          _,
          v,
          x,
          y
        );
        return;
      }
    }
    A & 8 ? (E & 16 && nt(b, g, _), w !== b && a(d, w)) : E & 16 ? A & 16 ? At(
      b,
      w,
      d,
      m,
      g,
      _,
      v,
      x,
      y
    ) : nt(b, g, _, !0) : (E & 8 && a(d, ""), A & 16 && Me(
      w,
      d,
      m,
      g,
      _,
      v,
      x,
      y
    ));
  }, Ke = (c, u, d, m, g, _, v, x, y) => {
    c = c || Ye, u = u || Ye;
    const b = c.length, E = u.length, w = Math.min(b, E);
    let T;
    for (T = 0; T < w; T++) {
      const A = u[T] = y ? Fe(u[T]) : ve(u[T]);
      F(
        c[T],
        A,
        d,
        null,
        g,
        _,
        v,
        x,
        y
      );
    }
    b > E ? nt(
      c,
      g,
      _,
      !0,
      !1,
      w
    ) : Me(
      u,
      d,
      m,
      g,
      _,
      v,
      x,
      y,
      w
    );
  }, At = (c, u, d, m, g, _, v, x, y) => {
    let b = 0;
    const E = u.length;
    let w = c.length - 1, T = E - 1;
    for (; b <= w && b <= T; ) {
      const A = c[b], I = u[b] = y ? Fe(u[b]) : ve(u[b]);
      if (lt(A, I))
        F(
          A,
          I,
          d,
          null,
          g,
          _,
          v,
          x,
          y
        );
      else
        break;
      b++;
    }
    for (; b <= w && b <= T; ) {
      const A = c[w], I = u[T] = y ? Fe(u[T]) : ve(u[T]);
      if (lt(A, I))
        F(
          A,
          I,
          d,
          null,
          g,
          _,
          v,
          x,
          y
        );
      else
        break;
      w--, T--;
    }
    if (b > w) {
      if (b <= T) {
        const A = T + 1, I = A < E ? u[A].el : m;
        for (; b <= T; )
          F(
            null,
            u[b] = y ? Fe(u[b]) : ve(u[b]),
            d,
            I,
            g,
            _,
            v,
            x,
            y
          ), b++;
      }
    } else if (b > T)
      for (; b <= w; )
        _e(c[b], g, _, !0), b++;
    else {
      const A = b, I = b, L = /* @__PURE__ */ new Map();
      for (b = I; b <= T; b++) {
        const oe = u[b] = y ? Fe(u[b]) : ve(u[b]);
        oe.key != null && L.set(oe.key, b);
      }
      let $, ie = 0;
      const z = T - I + 1;
      let he = !1, re = 0;
      const rt = new Array(z);
      for (b = 0; b < z; b++) rt[b] = 0;
      for (b = A; b <= w; b++) {
        const oe = c[b];
        if (ie >= z) {
          _e(oe, g, _, !0);
          continue;
        }
        let me;
        if (oe.key != null)
          me = L.get(oe.key);
        else
          for ($ = I; $ <= T; $++)
            if (rt[$ - I] === 0 && lt(oe, u[$])) {
              me = $;
              break;
            }
        me === void 0 ? _e(oe, g, _, !0) : (rt[me - I] = b + 1, me >= re ? re = me : he = !0, F(
          oe,
          u[me],
          d,
          null,
          g,
          _,
          v,
          x,
          y
        ), ie++);
      }
      const tn = he ? _o(rt) : Ye;
      for ($ = tn.length - 1, b = z - 1; b >= 0; b--) {
        const oe = I + b, me = u[oe], sn = oe + 1 < E ? u[oe + 1].el : m;
        rt[b] === 0 ? F(
          null,
          me,
          d,
          sn,
          g,
          _,
          v,
          x,
          y
        ) : he && ($ < 0 || b !== tn[$] ? Ue(me, d, sn, 2) : $--);
      }
    }
  }, Ue = (c, u, d, m, g = null) => {
    const { el: _, type: v, transition: x, children: y, shapeFlag: b } = c;
    if (b & 6) {
      Ue(c.component.subTree, u, d, m);
      return;
    }
    if (b & 128) {
      c.suspense.move(u, d, m);
      return;
    }
    if (b & 64) {
      v.move(c, u, d, it);
      return;
    }
    if (v === xe) {
      n(_, u, d);
      for (let w = 0; w < y.length; w++)
        Ue(y[w], u, d, m);
      n(c.anchor, u, d);
      return;
    }
    if (v === as) {
      q(c, u, d);
      return;
    }
    if (m !== 2 && b & 1 && x)
      if (m === 0)
        x.beforeEnter(_), n(_, u, d), le(() => x.enter(_), g);
      else {
        const { leave: w, delayLeave: T, afterLeave: A } = x, I = () => n(_, u, d), L = () => {
          w(_, () => {
            I(), A && A();
          });
        };
        T ? T(_, I, L) : L();
      }
    else
      n(_, u, d);
  }, _e = (c, u, d, m = !1, g = !1) => {
    const {
      type: _,
      props: v,
      ref: x,
      children: y,
      dynamicChildren: b,
      shapeFlag: E,
      patchFlag: w,
      dirs: T,
      cacheIndex: A
    } = c;
    if (w === -2 && (g = !1), x != null && xs(x, null, d, c, !0), A != null && (u.renderCache[A] = void 0), E & 256) {
      u.ctx.deactivate(c);
      return;
    }
    const I = E & 1 && T, L = !ht(c);
    let $;
    if (L && ($ = v && v.onVnodeBeforeUnmount) && be($, u, c), E & 6)
      Vi(c.component, d, m);
    else {
      if (E & 128) {
        c.suspense.unmount(d, m);
        return;
      }
      I && Ve(c, null, u, "beforeUnmount"), E & 64 ? c.type.remove(
        c,
        u,
        d,
        it,
        m
      ) : b && // #5154
      // when v-once is used inside a block, setBlockTracking(-1) marks the
      // parent block with hasOnce: true
      // so that it doesn't take the fast path during unmount - otherwise
      // components nested in v-once are never unmounted.
      !b.hasOnce && // #1153: fast path should not be taken for non-stable (v-for) fragments
      (_ !== xe || w > 0 && w & 64) ? nt(
        b,
        u,
        d,
        !1,
        !0
      ) : (_ === xe && w & 384 || !g && E & 16) && nt(y, u, d), m && Qs(c);
    }
    (L && ($ = v && v.onVnodeUnmounted) || I) && le(() => {
      $ && be($, u, c), I && Ve(c, null, u, "unmounted");
    }, d);
  }, Qs = (c) => {
    const { type: u, el: d, anchor: m, transition: g } = c;
    if (u === xe) {
      Ui(d, m);
      return;
    }
    if (u === as) {
      P(c);
      return;
    }
    const _ = () => {
      i(d), g && !g.persisted && g.afterLeave && g.afterLeave();
    };
    if (c.shapeFlag & 1 && g && !g.persisted) {
      const { leave: v, delayLeave: x } = g, y = () => v(d, _);
      x ? x(c.el, _, y) : y();
    } else
      _();
  }, Ui = (c, u) => {
    let d;
    for (; c !== u; )
      d = C(c), i(c), c = d;
    i(u);
  }, Vi = (c, u, d) => {
    const { bum: m, scope: g, job: _, subTree: v, um: x, m: y, a: b } = c;
    pn(y), pn(b), m && ss(m), g.stop(), _ && (_.flags |= 8, _e(v, c, u, d)), x && le(x, u), le(() => {
      c.isUnmounted = !0;
    }, u), u && u.pendingBranch && !u.isUnmounted && c.asyncDep && !c.asyncResolved && c.suspenseId === u.pendingId && (u.deps--, u.deps === 0 && u.resolve());
  }, nt = (c, u, d, m = !1, g = !1, _ = 0) => {
    for (let v = _; v < c.length; v++)
      _e(c[v], u, d, m, g);
  }, Ot = (c) => {
    if (c.shapeFlag & 6)
      return Ot(c.component.subTree);
    if (c.shapeFlag & 128)
      return c.suspense.next();
    const u = C(c.anchor || c.el), d = u && u[Dr];
    return d ? C(d) : u;
  };
  let es = !1;
  const Xs = (c, u, d) => {
    c == null ? u._vnode && _e(u._vnode, null, null, !0) : F(
      u._vnode || null,
      c,
      u,
      null,
      null,
      null,
      d
    ), u._vnode = c, es || (es = !0, cn(), ui(), es = !1);
  }, it = {
    p: F,
    um: _e,
    m: Ue,
    r: Qs,
    mt: Zt,
    mc: Me,
    pc: H,
    pbc: Le,
    n: Ot,
    o: e
  };
  let Zs, en;
  return {
    render: Xs,
    hydrate: Zs,
    createApp: io(Xs, Zs)
  };
}
function cs({ type: e, props: t }, s) {
  return s === "svg" && e === "foreignObject" || s === "mathml" && e === "annotation-xml" && t && t.encoding && t.encoding.includes("html") ? void 0 : s;
}
function Be({ effect: e, job: t }, s) {
  s ? (e.flags |= 32, t.flags |= 4) : (e.flags &= -33, t.flags &= -5);
}
function go(e, t) {
  return (!e || e && !e.pendingBranch) && t && !t.persisted;
}
function Ai(e, t, s = !1) {
  const n = e.children, i = t.children;
  if (O(n) && O(i))
    for (let r = 0; r < n.length; r++) {
      const o = n[r];
      let l = i[r];
      l.shapeFlag & 1 && !l.dynamicChildren && ((l.patchFlag <= 0 || l.patchFlag === 32) && (l = i[r] = Fe(i[r]), l.el = o.el), !s && l.patchFlag !== -2 && Ai(o, l)), l.type === Xt && (l.el = o.el);
    }
}
function _o(e) {
  const t = e.slice(), s = [0];
  let n, i, r, o, l;
  const f = e.length;
  for (n = 0; n < f; n++) {
    const h = e[n];
    if (h !== 0) {
      if (i = s[s.length - 1], e[i] < h) {
        t[n] = i, s.push(n);
        continue;
      }
      for (r = 0, o = s.length - 1; r < o; )
        l = r + o >> 1, e[s[l]] < h ? r = l + 1 : o = l;
      h < e[s[r]] && (r > 0 && (t[n] = s[r - 1]), s[r] = n);
    }
  }
  for (r = s.length, o = s[r - 1]; r-- > 0; )
    s[r] = o, o = t[o];
  return s;
}
function Oi(e) {
  const t = e.subTree.component;
  if (t)
    return t.asyncDep && !t.asyncResolved ? t : Oi(t);
}
function pn(e) {
  if (e)
    for (let t = 0; t < e.length; t++)
      e[t].flags |= 8;
}
const mo = Symbol.for("v-scx"), bo = () => Nt(mo);
function yo(e, t) {
  return ks(e, null, t);
}
function fs(e, t, s) {
  return ks(e, t, s);
}
function ks(e, t, s = U) {
  const { immediate: n, deep: i, flush: r, once: o } = s, l = k({}, s), f = t && n || !t && r !== "post";
  let h;
  if (wt) {
    if (r === "sync") {
      const S = bo();
      h = S.__watcherHandles || (S.__watcherHandles = []);
    } else if (!f) {
      const S = () => {
      };
      return S.stop = Ce, S.resume = Ce, S.pause = Ce, S;
    }
  }
  const a = ne;
  l.call = (S, M, F) => Se(S, a, M, F);
  let p = !1;
  r === "post" ? l.scheduler = (S) => {
    le(S, a && a.suspense);
  } : r !== "sync" && (p = !0, l.scheduler = (S, M) => {
    M ? S() : Us(S);
  }), l.augmentJob = (S) => {
    t && (S.flags |= 4), p && (S.flags |= 2, a && (S.id = a.uid, S.i = a));
  };
  const C = Rr(e, t, l);
  return wt && (h ? h.push(C) : f && C()), C;
}
function xo(e, t, s) {
  const n = this.proxy, i = G(e) ? e.includes(".") ? Ri(n, e) : () => n[e] : e.bind(n, n);
  let r;
  R(t) ? r = t : (r = t.handler, s = t);
  const o = St(this), l = ks(i, r.bind(n), s);
  return o(), l;
}
function Ri(e, t) {
  const s = t.split(".");
  return () => {
    let n = e;
    for (let i = 0; i < s.length && n; i++)
      n = n[s[i]];
    return n;
  };
}
const vo = (e, t) => t === "modelValue" || t === "model-value" ? e.modelModifiers : e[`${t}Modifiers`] || e[`${de(t)}Modifiers`] || e[`${fe(t)}Modifiers`];
function wo(e, t, ...s) {
  if (e.isUnmounted) return;
  const n = e.vnode.props || U;
  let i = s;
  const r = t.startsWith("update:"), o = r && vo(n, t.slice(7));
  o && (o.trim && (i = s.map((a) => G(a) ? a.trim() : a)), o.number && (i = s.map(Ji)));
  let l, f = n[l = ts(t)] || // also try camelCase event handler (#2249)
  n[l = ts(de(t))];
  !f && r && (f = n[l = ts(fe(t))]), f && Se(
    f,
    e,
    6,
    i
  );
  const h = n[l + "Once"];
  if (h) {
    if (!e.emitted)
      e.emitted = {};
    else if (e.emitted[l])
      return;
    e.emitted[l] = !0, Se(
      h,
      e,
      6,
      i
    );
  }
}
function Mi(e, t, s = !1) {
  const n = t.emitsCache, i = n.get(e);
  if (i !== void 0)
    return i;
  const r = e.emits;
  let o = {}, l = !1;
  if (!R(e)) {
    const f = (h) => {
      const a = Mi(h, t, !0);
      a && (l = !0, k(o, a));
    };
    !s && t.mixins.length && t.mixins.forEach(f), e.extends && f(e.extends), e.mixins && e.mixins.forEach(f);
  }
  return !r && !l ? (B(e) && n.set(e, null), null) : (O(r) ? r.forEach((f) => o[f] = null) : k(o, r), B(e) && n.set(e, o), o);
}
function Qt(e, t) {
  return !e || !Bt(t) ? !1 : (t = t.slice(2).replace(/Once$/, ""), N(e, t[0].toLowerCase() + t.slice(1)) || N(e, fe(t)) || N(e, t));
}
function us(e) {
  const {
    type: t,
    vnode: s,
    proxy: n,
    withProxy: i,
    propsOptions: [r],
    slots: o,
    attrs: l,
    emit: f,
    render: h,
    renderCache: a,
    props: p,
    data: C,
    setupState: S,
    ctx: M,
    inheritAttrs: F
  } = e, Z = Kt(e);
  let j, W;
  try {
    if (s.shapeFlag & 4) {
      const P = i || n, J = P;
      j = ve(
        h.call(
          J,
          P,
          a,
          p,
          S,
          C,
          M
        )
      ), W = l;
    } else {
      const P = t;
      j = ve(
        P.length > 1 ? P(
          p,
          { attrs: l, slots: o, emit: f }
        ) : P(
          p,
          null
        )
      ), W = t.props ? l : Co(l);
    }
  } catch (P) {
    pt.length = 0, zt(P, e, 1), j = Ae(xt);
  }
  let q = j;
  if (W && F !== !1) {
    const P = Object.keys(W), { shapeFlag: J } = q;
    P.length && J & 7 && (r && P.some(Os) && (W = So(
      W,
      r
    )), q = tt(q, W, !1, !0));
  }
  return s.dirs && (q = tt(q, null, !1, !0), q.dirs = q.dirs ? q.dirs.concat(s.dirs) : s.dirs), s.transition && Vs(q, s.transition), j = q, Kt(Z), j;
}
const Co = (e) => {
  let t;
  for (const s in e)
    (s === "class" || s === "style" || Bt(s)) && ((t || (t = {}))[s] = e[s]);
  return t;
}, So = (e, t) => {
  const s = {};
  for (const n in e)
    (!Os(n) || !(n.slice(9) in t)) && (s[n] = e[n]);
  return s;
};
function To(e, t, s) {
  const { props: n, children: i, component: r } = e, { props: o, children: l, patchFlag: f } = t, h = r.emitsOptions;
  if (t.dirs || t.transition)
    return !0;
  if (s && f >= 0) {
    if (f & 1024)
      return !0;
    if (f & 16)
      return n ? gn(n, o, h) : !!o;
    if (f & 8) {
      const a = t.dynamicProps;
      for (let p = 0; p < a.length; p++) {
        const C = a[p];
        if (o[C] !== n[C] && !Qt(h, C))
          return !0;
      }
    }
  } else
    return (i || l) && (!l || !l.$stable) ? !0 : n === o ? !1 : n ? o ? gn(n, o, h) : !0 : !!o;
  return !1;
}
function gn(e, t, s) {
  const n = Object.keys(t);
  if (n.length !== Object.keys(e).length)
    return !0;
  for (let i = 0; i < n.length; i++) {
    const r = n[i];
    if (t[r] !== e[r] && !Qt(s, r))
      return !0;
  }
  return !1;
}
function Eo({ vnode: e, parent: t }, s) {
  for (; t; ) {
    const n = t.subTree;
    if (n.suspense && n.suspense.activeBranch === e && (n.el = e.el), n === e)
      (e = t.vnode).el = s, t = t.parent;
    else
      break;
  }
}
const Ii = (e) => e.__isSuspense;
function Po(e, t) {
  t && t.pendingBranch ? O(e) ? t.effects.push(...e) : t.effects.push(e) : Fr(e);
}
const xe = Symbol.for("v-fgt"), Xt = Symbol.for("v-txt"), xt = Symbol.for("v-cmt"), as = Symbol.for("v-stc"), pt = [];
let ue = null;
function Dt(e = !1) {
  pt.push(ue = e ? null : []);
}
function Ao() {
  pt.pop(), ue = pt[pt.length - 1] || null;
}
let vt = 1;
function _n(e) {
  vt += e, e < 0 && ue && (ue.hasOnce = !0);
}
function Fi(e) {
  return e.dynamicChildren = vt > 0 ? ue || Ye : null, Ao(), vt > 0 && ue && ue.push(e), e;
}
function Ts(e, t, s, n, i, r) {
  return Fi(
    gt(
      e,
      t,
      s,
      n,
      i,
      r,
      !0
    )
  );
}
function Oo(e, t, s, n, i) {
  return Fi(
    Ae(
      e,
      t,
      s,
      n,
      i,
      !0
    )
  );
}
function Ni(e) {
  return e ? e.__v_isVNode === !0 : !1;
}
function lt(e, t) {
  return e.type === t.type && e.key === t.key;
}
const Di = ({ key: e }) => e ?? null, $t = ({
  ref: e,
  ref_key: t,
  ref_for: s
}) => (typeof e == "number" && (e = "" + e), e != null ? G(e) || X(e) || R(e) ? { i: we, r: e, k: t, f: !!s } : e : null);
function gt(e, t = null, s = null, n = 0, i = null, r = e === xe ? 0 : 1, o = !1, l = !1) {
  const f = {
    __v_isVNode: !0,
    __v_skip: !0,
    type: e,
    props: t,
    key: t && Di(t),
    ref: t && $t(t),
    scopeId: hi,
    slotScopeIds: null,
    children: s,
    component: null,
    suspense: null,
    ssContent: null,
    ssFallback: null,
    dirs: null,
    transition: null,
    el: null,
    anchor: null,
    target: null,
    targetStart: null,
    targetAnchor: null,
    staticCount: 0,
    shapeFlag: r,
    patchFlag: n,
    dynamicProps: i,
    dynamicChildren: null,
    appContext: null,
    ctx: we
  };
  return l ? (Gs(f, s), r & 128 && e.normalize(f)) : s && (f.shapeFlag |= G(s) ? 8 : 16), vt > 0 && // avoid a block node from tracking itself
  !o && // has current parent block
  ue && // presence of a patch flag indicates this node needs patching on updates.
  // component nodes also should always be patched, because even if the
  // component doesn't need to update, it needs to persist the instance on to
  // the next vnode so that it can be properly unmounted later.
  (f.patchFlag > 0 || r & 6) && // the EVENTS flag is only for hydration and if it is the only flag, the
  // vnode should not be considered dynamic due to handler caching.
  f.patchFlag !== 32 && ue.push(f), f;
}
const Ae = Ro;
function Ro(e, t = null, s = null, n = 0, i = null, r = !1) {
  if ((!e || e === zr) && (e = xt), Ni(e)) {
    const l = tt(
      e,
      t,
      !0
      /* mergeRef: true */
    );
    return s && Gs(l, s), vt > 0 && !r && ue && (l.shapeFlag & 6 ? ue[ue.indexOf(e)] = l : ue.push(l)), l.patchFlag = -2, l;
  }
  if (Ko(e) && (e = e.__vccOpts), t) {
    t = Mo(t);
    let { class: l, style: f } = t;
    l && !G(l) && (t.class = _t(l)), B(f) && (Ks(f) && !O(f) && (f = k({}, f)), t.style = Is(f));
  }
  const o = G(e) ? 1 : Ii(e) ? 128 : $r(e) ? 64 : B(e) ? 4 : R(e) ? 2 : 0;
  return gt(
    e,
    t,
    s,
    n,
    i,
    o,
    r,
    !0
  );
}
function Mo(e) {
  return e ? Ks(e) || vi(e) ? k({}, e) : e : null;
}
function tt(e, t, s = !1, n = !1) {
  const { props: i, ref: r, patchFlag: o, children: l, transition: f } = e, h = t ? $i(i || {}, t) : i, a = {
    __v_isVNode: !0,
    __v_skip: !0,
    type: e.type,
    props: h,
    key: h && Di(h),
    ref: t && t.ref ? (
      // #2078 in the case of <component :is="vnode" ref="extra"/>
      // if the vnode itself already has a ref, cloneVNode will need to merge
      // the refs so the single vnode can be set on multiple refs
      s && r ? O(r) ? r.concat($t(t)) : [r, $t(t)] : $t(t)
    ) : r,
    scopeId: e.scopeId,
    slotScopeIds: e.slotScopeIds,
    children: l,
    target: e.target,
    targetStart: e.targetStart,
    targetAnchor: e.targetAnchor,
    staticCount: e.staticCount,
    shapeFlag: e.shapeFlag,
    // if the vnode is cloned with extra props, we can no longer assume its
    // existing patch flag to be reliable and need to add the FULL_PROPS flag.
    // note: preserve flag for fragments since they use the flag for children
    // fast paths only.
    patchFlag: t && e.type !== xe ? o === -1 ? 16 : o | 16 : o,
    dynamicProps: e.dynamicProps,
    dynamicChildren: e.dynamicChildren,
    appContext: e.appContext,
    dirs: e.dirs,
    transition: f,
    // These should technically only be non-null on mounted VNodes. However,
    // they *should* be copied for kept-alive vnodes. So we just always copy
    // them since them being non-null during a mount doesn't affect the logic as
    // they will simply be overwritten.
    component: e.component,
    suspense: e.suspense,
    ssContent: e.ssContent && tt(e.ssContent),
    ssFallback: e.ssFallback && tt(e.ssFallback),
    el: e.el,
    anchor: e.anchor,
    ctx: e.ctx,
    ce: e.ce
  };
  return f && n && Vs(
    a,
    f.clone(a)
  ), a;
}
function Io(e = " ", t = 0) {
  return Ae(Xt, null, e, t);
}
function ve(e) {
  return e == null || typeof e == "boolean" ? Ae(xt) : O(e) ? Ae(
    xe,
    null,
    // #3666, avoid reference pollution when reusing vnode
    e.slice()
  ) : Ni(e) ? Fe(e) : Ae(Xt, null, String(e));
}
function Fe(e) {
  return e.el === null && e.patchFlag !== -1 || e.memo ? e : tt(e);
}
function Gs(e, t) {
  let s = 0;
  const { shapeFlag: n } = e;
  if (t == null)
    t = null;
  else if (O(t))
    s = 16;
  else if (typeof t == "object")
    if (n & 65) {
      const i = t.default;
      i && (i._c && (i._d = !1), Gs(e, i()), i._c && (i._d = !0));
      return;
    } else {
      s = 32;
      const i = t._;
      !i && !vi(t) ? t._ctx = we : i === 3 && we && (we.slots._ === 1 ? t._ = 1 : (t._ = 2, e.patchFlag |= 1024));
    }
  else R(t) ? (t = { default: t, _ctx: we }, s = 32) : (t = String(t), n & 64 ? (s = 16, t = [Io(t)]) : s = 8);
  e.children = t, e.shapeFlag |= s;
}
function $i(...e) {
  const t = {};
  for (let s = 0; s < e.length; s++) {
    const n = e[s];
    for (const i in n)
      if (i === "class")
        t.class !== n.class && (t.class = _t([t.class, n.class]));
      else if (i === "style")
        t.style = Is([t.style, n.style]);
      else if (Bt(i)) {
        const r = t[i], o = n[i];
        o && r !== o && !(O(r) && r.includes(o)) && (t[i] = r ? [].concat(r, o) : o);
      } else i !== "" && (t[i] = n[i]);
  }
  return t;
}
function be(e, t, s, n = null) {
  Se(e, t, 7, [
    s,
    n
  ]);
}
const Fo = bi();
let No = 0;
function Do(e, t, s) {
  const n = e.type, i = (t ? t.appContext : e.appContext) || Fo, r = {
    uid: No++,
    vnode: e,
    type: n,
    parent: t,
    appContext: i,
    root: null,
    // to be immediately set
    next: null,
    subTree: null,
    // will be set synchronously right after creation
    effect: null,
    update: null,
    // will be set synchronously right after creation
    job: null,
    scope: new tr(
      !0
      /* detached */
    ),
    render: null,
    proxy: null,
    exposed: null,
    exposeProxy: null,
    withProxy: null,
    provides: t ? t.provides : Object.create(i.provides),
    ids: t ? t.ids : ["", 0, 0],
    accessCache: null,
    renderCache: [],
    // local resolved assets
    components: null,
    directives: null,
    // resolved props and emits options
    propsOptions: Ci(n, i),
    emitsOptions: Mi(n, i),
    // emit
    emit: null,
    // to be set immediately
    emitted: null,
    // props default value
    propsDefaults: U,
    // inheritAttrs
    inheritAttrs: n.inheritAttrs,
    // state
    ctx: U,
    data: U,
    props: U,
    attrs: U,
    slots: U,
    refs: U,
    setupState: U,
    setupContext: null,
    // suspense related
    suspense: s,
    suspenseId: s ? s.pendingId : 0,
    asyncDep: null,
    asyncResolved: !1,
    // lifecycle hooks
    // not using enums here because it results in computed properties
    isMounted: !1,
    isUnmounted: !1,
    isDeactivated: !1,
    bc: null,
    c: null,
    bm: null,
    m: null,
    bu: null,
    u: null,
    um: null,
    bum: null,
    da: null,
    a: null,
    rtg: null,
    rtc: null,
    ec: null,
    sp: null
  };
  return r.ctx = { _: r }, r.root = t ? t.root : r, r.emit = wo.bind(null, r), e.ce && e.ce(r), r;
}
let ne = null, Vt, Es;
{
  const e = Gt(), t = (s, n) => {
    let i;
    return (i = e[s]) || (i = e[s] = []), i.push(n), (r) => {
      i.length > 1 ? i.forEach((o) => o(r)) : i[0](r);
    };
  };
  Vt = t(
    "__VUE_INSTANCE_SETTERS__",
    (s) => ne = s
  ), Es = t(
    "__VUE_SSR_SETTERS__",
    (s) => wt = s
  );
}
const St = (e) => {
  const t = ne;
  return Vt(e), e.scope.on(), () => {
    e.scope.off(), Vt(t);
  };
}, mn = () => {
  ne && ne.scope.off(), Vt(null);
};
function ji(e) {
  return e.vnode.shapeFlag & 4;
}
let wt = !1;
function $o(e, t = !1, s = !1) {
  t && Es(t);
  const { props: n, children: i } = e.vnode, r = ji(e);
  oo(e, n, r, t), uo(e, i, s);
  const o = r ? jo(e, t) : void 0;
  return t && Es(!1), o;
}
function jo(e, t) {
  const s = e.type;
  e.accessCache = /* @__PURE__ */ Object.create(null), e.proxy = new Proxy(e.ctx, Qr);
  const { setup: n } = s;
  if (n) {
    je();
    const i = e.setupContext = n.length > 1 ? Lo(e) : null, r = St(e), o = Ct(
      n,
      e,
      0,
      [
        e.props,
        i
      ]
    ), l = Dn(o);
    if (He(), r(), (l || e.sp) && !ht(e) && di(e), l) {
      if (o.then(mn, mn), t)
        return o.then((f) => {
          bn(e, f, t);
        }).catch((f) => {
          zt(f, e, 0);
        });
      e.asyncDep = o;
    } else
      bn(e, o, t);
  } else
    Hi(e, t);
}
function bn(e, t, s) {
  R(t) ? e.type.__ssrInlineRender ? e.ssrRender = t : e.render = t : B(t) && (e.setupState = oi(t)), Hi(e, s);
}
let yn;
function Hi(e, t, s) {
  const n = e.type;
  if (!e.render) {
    if (!t && yn && !n.render) {
      const i = n.template || Ws(e).template;
      if (i) {
        const { isCustomElement: r, compilerOptions: o } = e.appContext.config, { delimiters: l, compilerOptions: f } = n, h = k(
          k(
            {
              isCustomElement: r,
              delimiters: l
            },
            o
          ),
          f
        );
        n.render = yn(i, h);
      }
    }
    e.render = n.render || Ce;
  }
  {
    const i = St(e);
    je();
    try {
      Xr(e);
    } finally {
      He(), i();
    }
  }
}
const Ho = {
  get(e, t) {
    return Y(e, "get", ""), e[t];
  }
};
function Lo(e) {
  const t = (s) => {
    e.exposed = s || {};
  };
  return {
    attrs: new Proxy(e.attrs, Ho),
    slots: e.slots,
    emit: e.emit,
    expose: t
  };
}
function Js(e) {
  return e.exposed ? e.exposeProxy || (e.exposeProxy = new Proxy(oi(wr(e.exposed)), {
    get(t, s) {
      if (s in t)
        return t[s];
      if (s in dt)
        return dt[s](e);
    },
    has(t, s) {
      return s in t || s in dt;
    }
  })) : e.proxy;
}
function Ko(e) {
  return R(e) && "__vccOpts" in e;
}
const ze = (e, t) => Ar(e, t, wt), Uo = "3.5.12";
/**
* @vue/runtime-dom v3.5.12
* (c) 2018-present Yuxi (Evan) You and Vue contributors
* @license MIT
**/
let Ps;
const xn = typeof window < "u" && window.trustedTypes;
if (xn)
  try {
    Ps = /* @__PURE__ */ xn.createPolicy("vue", {
      createHTML: (e) => e
    });
  } catch {
  }
const Li = Ps ? (e) => Ps.createHTML(e) : (e) => e, Vo = "http://www.w3.org/2000/svg", Bo = "http://www.w3.org/1998/Math/MathML", Ee = typeof document < "u" ? document : null, vn = Ee && /* @__PURE__ */ Ee.createElement("template"), Wo = {
  insert: (e, t, s) => {
    t.insertBefore(e, s || null);
  },
  remove: (e) => {
    const t = e.parentNode;
    t && t.removeChild(e);
  },
  createElement: (e, t, s, n) => {
    const i = t === "svg" ? Ee.createElementNS(Vo, e) : t === "mathml" ? Ee.createElementNS(Bo, e) : s ? Ee.createElement(e, { is: s }) : Ee.createElement(e);
    return e === "select" && n && n.multiple != null && i.setAttribute("multiple", n.multiple), i;
  },
  createText: (e) => Ee.createTextNode(e),
  createComment: (e) => Ee.createComment(e),
  setText: (e, t) => {
    e.nodeValue = t;
  },
  setElementText: (e, t) => {
    e.textContent = t;
  },
  parentNode: (e) => e.parentNode,
  nextSibling: (e) => e.nextSibling,
  querySelector: (e) => Ee.querySelector(e),
  setScopeId(e, t) {
    e.setAttribute(t, "");
  },
  // __UNSAFE__
  // Reason: innerHTML.
  // Static content here can only come from compiled templates.
  // As long as the user only uses trusted templates, this is safe.
  insertStaticContent(e, t, s, n, i, r) {
    const o = s ? s.previousSibling : t.lastChild;
    if (i && (i === r || i.nextSibling))
      for (; t.insertBefore(i.cloneNode(!0), s), !(i === r || !(i = i.nextSibling)); )
        ;
    else {
      vn.innerHTML = Li(
        n === "svg" ? `<svg>${e}</svg>` : n === "mathml" ? `<math>${e}</math>` : e
      );
      const l = vn.content;
      if (n === "svg" || n === "mathml") {
        const f = l.firstChild;
        for (; f.firstChild; )
          l.appendChild(f.firstChild);
        l.removeChild(f);
      }
      t.insertBefore(l, s);
    }
    return [
      // first
      o ? o.nextSibling : t.firstChild,
      // last
      s ? s.previousSibling : t.lastChild
    ];
  }
}, qo = Symbol("_vtc");
function ko(e, t, s) {
  const n = e[qo];
  n && (t = (t ? [t, ...n] : [...n]).join(" ")), t == null ? e.removeAttribute("class") : s ? e.setAttribute("class", t) : e.className = t;
}
const wn = Symbol("_vod"), Go = Symbol("_vsh"), Jo = Symbol(""), zo = /(^|;)\s*display\s*:/;
function Yo(e, t, s) {
  const n = e.style, i = G(s);
  let r = !1;
  if (s && !i) {
    if (t)
      if (G(t))
        for (const o of t.split(";")) {
          const l = o.slice(0, o.indexOf(":")).trim();
          s[l] == null && jt(n, l, "");
        }
      else
        for (const o in t)
          s[o] == null && jt(n, o, "");
    for (const o in s)
      o === "display" && (r = !0), jt(n, o, s[o]);
  } else if (i) {
    if (t !== s) {
      const o = n[Jo];
      o && (s += ";" + o), n.cssText = s, r = zo.test(s);
    }
  } else t && e.removeAttribute("style");
  wn in e && (e[wn] = r ? n.display : "", e[Go] && (n.display = "none"));
}
const Cn = /\s*!important$/;
function jt(e, t, s) {
  if (O(s))
    s.forEach((n) => jt(e, t, n));
  else if (s == null && (s = ""), t.startsWith("--"))
    e.setProperty(t, s);
  else {
    const n = Qo(e, t);
    Cn.test(s) ? e.setProperty(
      fe(n),
      s.replace(Cn, ""),
      "important"
    ) : e[n] = s;
  }
}
const Sn = ["Webkit", "Moz", "ms"], hs = {};
function Qo(e, t) {
  const s = hs[t];
  if (s)
    return s;
  let n = de(t);
  if (n !== "filter" && n in e)
    return hs[t] = n;
  n = jn(n);
  for (let i = 0; i < Sn.length; i++) {
    const r = Sn[i] + n;
    if (r in e)
      return hs[t] = r;
  }
  return t;
}
const Tn = "http://www.w3.org/1999/xlink";
function En(e, t, s, n, i, r = er(t)) {
  n && t.startsWith("xlink:") ? s == null ? e.removeAttributeNS(Tn, t.slice(6, t.length)) : e.setAttributeNS(Tn, t, s) : s == null || r && !Ln(s) ? e.removeAttribute(t) : e.setAttribute(
    t,
    r ? "" : $e(s) ? String(s) : s
  );
}
function Pn(e, t, s, n, i) {
  if (t === "innerHTML" || t === "textContent") {
    s != null && (e[t] = t === "innerHTML" ? Li(s) : s);
    return;
  }
  const r = e.tagName;
  if (t === "value" && r !== "PROGRESS" && // custom elements may use _value internally
  !r.includes("-")) {
    const l = r === "OPTION" ? e.getAttribute("value") || "" : e.value, f = s == null ? (
      // #11647: value should be set as empty string for null and undefined,
      // but <input type="checkbox"> should be set as 'on'.
      e.type === "checkbox" ? "on" : ""
    ) : String(s);
    (l !== f || !("_value" in e)) && (e.value = f), s == null && e.removeAttribute(t), e._value = s;
    return;
  }
  let o = !1;
  if (s === "" || s == null) {
    const l = typeof e[t];
    l === "boolean" ? s = Ln(s) : s == null && l === "string" ? (s = "", o = !0) : l === "number" && (s = 0, o = !0);
  }
  try {
    e[t] = s;
  } catch {
  }
  o && e.removeAttribute(i || t);
}
function Xo(e, t, s, n) {
  e.addEventListener(t, s, n);
}
function Zo(e, t, s, n) {
  e.removeEventListener(t, s, n);
}
const An = Symbol("_vei");
function el(e, t, s, n, i = null) {
  const r = e[An] || (e[An] = {}), o = r[t];
  if (n && o)
    o.value = n;
  else {
    const [l, f] = tl(t);
    if (n) {
      const h = r[t] = il(
        n,
        i
      );
      Xo(e, l, h, f);
    } else o && (Zo(e, l, o, f), r[t] = void 0);
  }
}
const On = /(?:Once|Passive|Capture)$/;
function tl(e) {
  let t;
  if (On.test(e)) {
    t = {};
    let n;
    for (; n = e.match(On); )
      e = e.slice(0, e.length - n[0].length), t[n[0].toLowerCase()] = !0;
  }
  return [e[2] === ":" ? e.slice(3) : fe(e.slice(2)), t];
}
let ds = 0;
const sl = /* @__PURE__ */ Promise.resolve(), nl = () => ds || (sl.then(() => ds = 0), ds = Date.now());
function il(e, t) {
  const s = (n) => {
    if (!n._vts)
      n._vts = Date.now();
    else if (n._vts <= s.attached)
      return;
    Se(
      rl(n, s.value),
      t,
      5,
      [n]
    );
  };
  return s.value = e, s.attached = nl(), s;
}
function rl(e, t) {
  if (O(t)) {
    const s = e.stopImmediatePropagation;
    return e.stopImmediatePropagation = () => {
      s.call(e), e._stopped = !0;
    }, t.map(
      (n) => (i) => !i._stopped && n && n(i)
    );
  } else
    return t;
}
const Rn = (e) => e.charCodeAt(0) === 111 && e.charCodeAt(1) === 110 && // lowercase letter
e.charCodeAt(2) > 96 && e.charCodeAt(2) < 123, ol = (e, t, s, n, i, r) => {
  const o = i === "svg";
  t === "class" ? ko(e, n, o) : t === "style" ? Yo(e, s, n) : Bt(t) ? Os(t) || el(e, t, s, n, r) : (t[0] === "." ? (t = t.slice(1), !0) : t[0] === "^" ? (t = t.slice(1), !1) : ll(e, t, n, o)) ? (Pn(e, t, n), !e.tagName.includes("-") && (t === "value" || t === "checked" || t === "selected") && En(e, t, n, o, r, t !== "value")) : /* #11081 force set props for possible async custom element */ e._isVueCE && (/[A-Z]/.test(t) || !G(n)) ? Pn(e, de(t), n, r, t) : (t === "true-value" ? e._trueValue = n : t === "false-value" && (e._falseValue = n), En(e, t, n, o));
};
function ll(e, t, s, n) {
  if (n)
    return !!(t === "innerHTML" || t === "textContent" || t in e && Rn(t) && R(s));
  if (t === "spellcheck" || t === "draggable" || t === "translate" || t === "form" || t === "list" && e.tagName === "INPUT" || t === "type" && e.tagName === "TEXTAREA")
    return !1;
  if (t === "width" || t === "height") {
    const i = e.tagName;
    if (i === "IMG" || i === "VIDEO" || i === "CANVAS" || i === "SOURCE")
      return !1;
  }
  return Rn(t) && G(s) ? !1 : t in e;
}
const Mn = {};
/*! #__NO_SIDE_EFFECTS__ */
// @__NO_SIDE_EFFECTS__
function cl(e, t, s) {
  const n = /* @__PURE__ */ Bs(e, t);
  qt(n) && k(n, t);
  class i extends zs {
    constructor(o) {
      super(n, o, s);
    }
  }
  return i.def = n, i;
}
const fl = typeof HTMLElement < "u" ? HTMLElement : class {
};
class zs extends fl {
  constructor(t, s = {}, n = Fn) {
    super(), this._def = t, this._props = s, this._createApp = n, this._isVueCE = !0, this._instance = null, this._app = null, this._nonce = this._def.nonce, this._connected = !1, this._resolved = !1, this._numberProps = null, this._styleChildren = /* @__PURE__ */ new WeakSet(), this._ob = null, this.shadowRoot && n !== Fn ? this._root = this.shadowRoot : t.shadowRoot !== !1 ? (this.attachShadow({ mode: "open" }), this._root = this.shadowRoot) : this._root = this, this._def.__asyncLoader || this._resolveProps(this._def);
  }
  connectedCallback() {
    if (!this.isConnected) return;
    this.shadowRoot || this._parseSlots(), this._connected = !0;
    let t = this;
    for (; t = t && (t.parentNode || t.host); )
      if (t instanceof zs) {
        this._parent = t;
        break;
      }
    this._instance || (this._resolved ? (this._setParent(), this._update()) : t && t._pendingResolve ? this._pendingResolve = t._pendingResolve.then(() => {
      this._pendingResolve = void 0, this._resolveDef();
    }) : this._resolveDef());
  }
  _setParent(t = this._parent) {
    t && (this._instance.parent = t._instance, this._instance.provides = t._instance.provides);
  }
  disconnectedCallback() {
    this._connected = !1, ci(() => {
      this._connected || (this._ob && (this._ob.disconnect(), this._ob = null), this._app && this._app.unmount(), this._instance && (this._instance.ce = void 0), this._app = this._instance = null);
    });
  }
  /**
   * resolve inner component definition (handle possible async component)
   */
  _resolveDef() {
    if (this._pendingResolve)
      return;
    for (let n = 0; n < this.attributes.length; n++)
      this._setAttr(this.attributes[n].name);
    this._ob = new MutationObserver((n) => {
      for (const i of n)
        this._setAttr(i.attributeName);
    }), this._ob.observe(this, { attributes: !0 });
    const t = (n, i = !1) => {
      this._resolved = !0, this._pendingResolve = void 0;
      const { props: r, styles: o } = n;
      let l;
      if (r && !O(r))
        for (const f in r) {
          const h = r[f];
          (h === Number || h && h.type === Number) && (f in this._props && (this._props[f] = nn(this._props[f])), (l || (l = /* @__PURE__ */ Object.create(null)))[de(f)] = !0);
        }
      this._numberProps = l, i && this._resolveProps(n), this.shadowRoot && this._applyStyles(o), this._mount(n);
    }, s = this._def.__asyncLoader;
    s ? this._pendingResolve = s().then(
      (n) => t(this._def = n, !0)
    ) : t(this._def);
  }
  _mount(t) {
    this._app = this._createApp(t), t.configureApp && t.configureApp(this._app), this._app._ceVNode = this._createVNode(), this._app.mount(this._root);
    const s = this._instance && this._instance.exposed;
    if (s)
      for (const n in s)
        N(this, n) || Object.defineProperty(this, n, {
          // unwrap ref to be consistent with public instance behavior
          get: () => ri(s[n])
        });
  }
  _resolveProps(t) {
    const { props: s } = t, n = O(s) ? s : Object.keys(s || {});
    for (const i of Object.keys(this))
      i[0] !== "_" && n.includes(i) && this._setProp(i, this[i]);
    for (const i of n.map(de))
      Object.defineProperty(this, i, {
        get() {
          return this._getProp(i);
        },
        set(r) {
          this._setProp(i, r, !0, !0);
        }
      });
  }
  _setAttr(t) {
    if (t.startsWith("data-v-")) return;
    const s = this.hasAttribute(t);
    let n = s ? this.getAttribute(t) : Mn;
    const i = de(t);
    s && this._numberProps && this._numberProps[i] && (n = nn(n)), this._setProp(i, n, !1, !0);
  }
  /**
   * @internal
   */
  _getProp(t) {
    return this._props[t];
  }
  /**
   * @internal
   */
  _setProp(t, s, n = !0, i = !1) {
    s !== this._props[t] && (s === Mn ? delete this._props[t] : (this._props[t] = s, t === "key" && this._app && (this._app._ceVNode.key = s)), i && this._instance && this._update(), n && (s === !0 ? this.setAttribute(fe(t), "") : typeof s == "string" || typeof s == "number" ? this.setAttribute(fe(t), s + "") : s || this.removeAttribute(fe(t))));
  }
  _update() {
    pl(this._createVNode(), this._root);
  }
  _createVNode() {
    const t = {};
    this.shadowRoot || (t.onVnodeMounted = t.onVnodeUpdated = this._renderSlots.bind(this));
    const s = Ae(this._def, k(t, this._props));
    return this._instance || (s.ce = (n) => {
      this._instance = n, n.ce = this, n.isCE = !0;
      const i = (r, o) => {
        this.dispatchEvent(
          new CustomEvent(
            r,
            qt(o[0]) ? k({ detail: o }, o[0]) : { detail: o }
          )
        );
      };
      n.emit = (r, ...o) => {
        i(r, o), fe(r) !== r && i(fe(r), o);
      }, this._setParent();
    }), s;
  }
  _applyStyles(t, s) {
    if (!t) return;
    if (s) {
      if (s === this._def || this._styleChildren.has(s))
        return;
      this._styleChildren.add(s);
    }
    const n = this._nonce;
    for (let i = t.length - 1; i >= 0; i--) {
      const r = document.createElement("style");
      n && r.setAttribute("nonce", n), r.textContent = t[i], this.shadowRoot.prepend(r);
    }
  }
  /**
   * Only called when shadowRoot is false
   */
  _parseSlots() {
    const t = this._slots = {};
    let s;
    for (; s = this.firstChild; ) {
      const n = s.nodeType === 1 && s.getAttribute("slot") || "default";
      (t[n] || (t[n] = [])).push(s), this.removeChild(s);
    }
  }
  /**
   * Only called when shadowRoot is false
   */
  _renderSlots() {
    const t = (this._teleportTarget || this).querySelectorAll("slot"), s = this._instance.type.__scopeId;
    for (let n = 0; n < t.length; n++) {
      const i = t[n], r = i.getAttribute("name") || "default", o = this._slots[r], l = i.parentNode;
      if (o)
        for (const f of o) {
          if (s && f.nodeType === 1) {
            const h = s + "-s", a = document.createTreeWalker(f, 1);
            f.setAttribute(h, "");
            let p;
            for (; p = a.nextNode(); )
              p.setAttribute(h, "");
          }
          l.insertBefore(f, i);
        }
      else
        for (; i.firstChild; ) l.insertBefore(i.firstChild, i);
      l.removeChild(i);
    }
  }
  /**
   * @internal
   */
  _injectChildStyle(t) {
    this._applyStyles(t.styles, t);
  }
  /**
   * @internal
   */
  _removeChildStyle(t) {
  }
}
const ul = ["ctrl", "shift", "alt", "meta"], al = {
  stop: (e) => e.stopPropagation(),
  prevent: (e) => e.preventDefault(),
  self: (e) => e.target !== e.currentTarget,
  ctrl: (e) => !e.ctrlKey,
  shift: (e) => !e.shiftKey,
  alt: (e) => !e.altKey,
  meta: (e) => !e.metaKey,
  left: (e) => "button" in e && e.button !== 0,
  middle: (e) => "button" in e && e.button !== 1,
  right: (e) => "button" in e && e.button !== 2,
  exact: (e, t) => ul.some((s) => e[`${s}Key`] && !t.includes(s))
}, Ft = (e, t) => {
  const s = e._withMods || (e._withMods = {}), n = t.join(".");
  return s[n] || (s[n] = (i, ...r) => {
    for (let o = 0; o < t.length; o++) {
      const l = al[t[o]];
      if (l && l(i, t)) return;
    }
    return e(i, ...r);
  });
}, hl = {
  esc: "escape",
  space: " ",
  up: "arrow-up",
  left: "arrow-left",
  right: "arrow-right",
  down: "arrow-down",
  delete: "backspace"
}, ps = (e, t) => {
  const s = e._withKeys || (e._withKeys = {}), n = t.join(".");
  return s[n] || (s[n] = (i) => {
    if (!("key" in i))
      return;
    const r = fe(i.key);
    if (t.some(
      (o) => o === r || hl[o] === r
    ))
      return e(i);
  });
}, dl = /* @__PURE__ */ k({ patchProp: ol }, Wo);
let In;
function Ki() {
  return In || (In = ho(dl));
}
const pl = (...e) => {
  Ki().render(...e);
}, Fn = (...e) => {
  const t = Ki().createApp(...e), { mount: s } = t;
  return t.mount = (n) => {
    const i = _l(n);
    if (!i) return;
    const r = t._component;
    !R(r) && !r.render && !r.template && (r.template = i.innerHTML), i.nodeType === 1 && (i.textContent = "");
    const o = s(i, !1, gl(i));
    return i instanceof Element && (i.removeAttribute("v-cloak"), i.setAttribute("data-v-app", "")), o;
  }, t;
};
function gl(e) {
  if (e instanceof SVGElement)
    return "svg";
  if (typeof MathMLElement == "function" && e instanceof MathMLElement)
    return "mathml";
}
function _l(e) {
  return G(e) ? document.querySelector(e) : e;
}
function ml(e, t = bl) {
  const s = [...e];
  for (let n = s.length - 1; n > 0; n--) {
    const i = t() % (n + 1);
    [s[n], s[i]] = [s[i], s[n]];
  }
  return s;
}
function bl() {
  return Math.floor(Math.random() * 2 ** 32);
}
const yl = ["aria-checked", "tabindex"], xl = /* @__PURE__ */ Bs({
  __name: "QCUChoice",
  props: {
    choice: {},
    maxChoicesPerLine: {},
    imageClass: {},
    nameClass: {},
    checked: { type: Boolean },
    focusable: { type: Boolean }
  },
  emits: ["check", "checkNext", "checkPrevious"],
  setup(e) {
    const t = e, s = Cr();
    yo(() => {
      var i;
      t.checked && ((i = s.value) == null || i.focus());
    });
    const n = `qcu-image-choice-${(t.choice.response ?? t.choice.name).replace(/[^\w\-_:.]/g, "")}`;
    return (i, r) => (Dt(), Ts("li", {
      ref_key: "el",
      ref: s,
      role: "radio",
      class: _t(["qcu-image__choice", `qcu-image__choice--max${t.maxChoicesPerLine}`]),
      "aria-labelledby": n,
      "aria-checked": i.checked,
      tabindex: i.focusable ? 0 : -1,
      onClick: r[0] || (r[0] = Ft((o) => i.$emit("check"), ["stop"])),
      onKeydown: [
        r[1] || (r[1] = ps(Ft((o) => i.$emit("check"), ["stop", "prevent"]), ["enter", "space"])),
        r[2] || (r[2] = ps(Ft((o) => i.$emit("checkPrevious"), ["stop", "prevent"]), ["up", "left"])),
        r[3] || (r[3] = ps(Ft((o) => i.$emit("checkNext"), ["stop", "prevent"]), ["down", "right"]))
      ]
    }, [
      gt("figure", null, [
        gt("img", $i(i.choice.image, {
          class: i.imageClass,
          alt: ""
        }), null, 16),
        gt("figcaption", {
          class: _t(i.nameClass),
          id: n
        }, Un(i.choice.name), 3)
      ])
    ], 42, yl));
  }
}), vl = ["aria-label"], wl = /* @__PURE__ */ Bs({
  __name: "QCUImage.ce",
  props: {
    props: { type: Object }
  },
  emits: ["answer"],
  setup(e, { emit: t }) {
    const s = t, n = ze(() => e.props.maxChoicesPerLine ?? 5), i = ze(() => e.props.hideChoicesName ? "sr-only" : ""), r = ze(() => e.props.imageChoicesSize ?? "icon"), o = ze(() => e.props.orderChoices ? e.props.choices : ml(e.props.choices)), l = Sr(), f = ze(() => l.value ?? o.value[0]);
    function h(a) {
      a === -1 ? l.value = o.value[o.value.length - 1] : a === o.value.length ? l.value = o.value[0] : l.value = o.value[a], s("answer", l.value.response ?? l.value.name);
    }
    return (a, p) => (Dt(), Ts("ul", {
      role: "radiogroup",
      class: "qcu-image",
      "aria-label": a.props.name
    }, [
      (Dt(!0), Ts(xe, null, Yr(o.value, (C, S) => (Dt(), Oo(xl, {
        choice: C,
        "max-choices-per-line": n.value,
        key: S,
        "name-class": i.value,
        "image-class": r.value,
        checked: C === l.value,
        focusable: C === f.value,
        onCheck: (M) => h(S),
        onCheckNext: (M) => h(S + 1),
        onCheckPrevious: (M) => h(S - 1)
      }, null, 8, ["choice", "max-choices-per-line", "name-class", "image-class", "checked", "focusable", "onCheck", "onCheckNext", "onCheckPrevious"]))), 128))
    ], 8, vl));
  }
}), Cl = '*{box-sizing:border-box}ul,li{padding:0;margin:0}li{list-style:none}.qcu-image{display:flex;width:100%;flex-wrap:wrap;justify-content:space-around;font-size:110%;font-family:Open Sans,Arial,sans-serif}.qcu-image__choice{display:flex;position:relative;flex-direction:column;align-items:center;justify-content:center;margin:1em;border:solid 2px #c1c7d0;border-radius:10px;background:#fff;width:calc(20% - 2em);min-width:120px}.qcu-image__choice--max4{width:calc(25% - 2em);min-width:175px}.qcu-image__choice--max3{width:calc(33% - 2em)}.qcu-image__choice--max2{width:calc(50% - 2em);min-width:300px}.qcu-image__choice--max1{width:calc(100% - 4em);min-width:300px}.qcu-image__choice:hover{cursor:pointer;box-shadow:0 3px 10px #0000001a;transform:scale(1.04);transition:transform ease-in .1s}.qcu-image__choice:focus{outline:none;box-shadow:5px 5px #ffbe00,-5px 5px #ffbe00,5px -5px #ffbe00,-5px -5px #ffbe00}.qcu-image__choice[aria-checked=true]{border-color:#3d68ff}.qcu-image__choice[aria-checked=true]:after{content:"";position:absolute;width:100%;height:100%;background:#3d68ff4d;border-radius:10px;z-index:3}.qcu-image__choice figure{width:100%;padding:0 10px;margin:3px;text-align:center}.qcu-image__choice figure img{max-width:100%;height:auto}.qcu-image__choice figure img.icon{max-height:100px;width:auto}.qcu-image__choice figure img.medium{max-width:50%;height:auto}.qcu-image__choice figure figcaption{margin:1ex}.sr-only{position:absolute;width:1px;height:1px;padding:0;margin:-1px;overflow:hidden;clip:rect(0,0,0,0);border:0}', Sl = (e, t) => {
  const s = e.__vccOpts || e;
  for (const [n, i] of t)
    s[n] = i;
  return s;
}, Tl = /* @__PURE__ */ Sl(wl, [["styles", [Cl]]]), El = /* @__PURE__ */ cl(Tl);
window.customElements.define("qcu-image", El);
