import { template as template_b9d9524b65e54f85adc8d0893aac7bcf } from "@ember/template-compiler";
import { t } from 'ember-intl';
export default template_b9d9524b65e54f85adc8d0893aac7bcf(`
  <footer id="footer" class="footer">
    <nav class="footer__navigation" role="navigation" aria-label={{t "navigation.footer.label"}}>
      <ul class="footer-navigation__list">
        <li>
          <a
            href="{{t 'navigation.footer.legal-notice-url'}}"
            target="_blank"
            class="footer-navigation__item"
            rel="noopener noreferrer"
          >
            {{t "navigation.footer.legal-notice"}}
          </a>
        </li>
        <li>
          <a
            href="{{t 'navigation.footer.student-data-protection-policy-url'}}"
            target="_blank"
            class="footer-navigation__item"
            rel="noopener noreferrer"
          >
            {{t "navigation.footer.student-data-protection-policy"}}
          </a>
        </li>
      </ul>
    </nav>
  </footer>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
