import { template as template_26545913e9bf483aaab45027c0ab8204 } from "@ember/template-compiler";
export default template_26545913e9bf483aaab45027c0ab8204(`
  <div class="card-wrapper">
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
