import { template as template_ce43b9291edb4b4e897251fe63856589 } from "@ember/template-compiler";
export default template_ce43b9291edb4b4e897251fe63856589(`
  <svg
    class={{if @class @class ""}}
    width="233"
    height="210"
    viewBox="0 0 233 210"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="background"
      d="M0.741577 12C0.741577 5.37258 6.11416 0 12.7416 0H220.742C227.369 0 232.742 5.37258 232.742 12V198L178.069 203.656C137.293 207.874 96.1902 207.874 55.4137 203.656L0.741577 198V12Z"
    />
    <g opacity="0.2">
      <path
        d="M183.692 70.8799C184.572 68.2799 203.432 74.1999 202.422 77.1999C200.682 82.3699 195.072 85.1499 189.902 83.4099C184.732 81.6699 181.952 76.0599 183.692 70.8899V70.8799Z"
        fill="white"
      />
      <path
        d="M39.6716 47.3498C38.5516 45.8998 48.8616 37.6198 50.1416 39.2998C52.3616 42.1898 51.8216 46.3398 48.9316 48.5598C46.0416 50.7798 41.8916 50.2398 39.6716 47.3498Z"
        fill="white"
      />
      <path
        d="M204.902 206.76C206.978 206.76 208.662 205.076 208.662 203C208.662 200.923 206.978 199.24 204.902 199.24C202.825 199.24 201.142 200.923 201.142 203C201.142 205.076 202.825 206.76 204.902 206.76Z"
        fill="white"
      />
      <path
        d="M51.4116 216.57C53.4882 216.57 55.1716 214.886 55.1716 212.81C55.1716 210.733 53.4882 209.05 51.4116 209.05C49.335 209.05 47.6516 210.733 47.6516 212.81C47.6516 214.886 49.335 216.57 51.4116 216.57Z"
        fill="white"
      />
      <path
        d="M28.1216 108.06C31.9268 108.06 35.0116 104.975 35.0116 101.17C35.0116 97.3645 31.9268 94.2798 28.1216 94.2798C24.3163 94.2798 21.2316 97.3645 21.2316 101.17C21.2316 104.975 24.3163 108.06 28.1216 108.06Z"
        fill="white"
      />
      <path
        d="M56.8515 24.6298C59.0772 24.6298 60.8815 22.8255 60.8815 20.5998C60.8815 18.3741 59.0772 16.5698 56.8515 16.5698C54.6258 16.5698 52.8215 18.3741 52.8215 20.5998C52.8215 22.8255 54.6258 24.6298 56.8515 24.6298Z"
        fill="white"
      />
      <path
        d="M199.132 180.42C202.318 180.42 204.902 177.837 204.902 174.65C204.902 171.463 202.318 168.88 199.132 168.88C195.945 168.88 193.362 171.463 193.362 174.65C193.362 177.837 195.945 180.42 199.132 180.42Z"
        fill="white"
      />
      <path
        d="M85.2815 213.04C87.0715 212.56 89.2115 212.23 90.3015 213.74C91.4715 215.35 90.4815 221.21 90.4315 221.9C90.3815 222.54 90.4715 228.76 89.0615 230.04C87.9315 231.07 86.2415 230.55 84.8215 229.98L83.1115 229.29C78.6915 227.06 75.4515 223.38 75.6315 221C75.8015 218.76 79.2115 215.11 84.3315 213.3L85.2815 213.04Z"
        fill="white"
      />
      <path
        d="M93.4016 37.6497C94.6316 35.7897 96.3016 33.7997 98.4816 34.2897C100.812 34.8097 104.582 40.8797 105.082 41.5397C105.542 42.1497 110.572 47.6897 110.322 49.9597C110.122 51.7897 108.182 52.6597 106.452 53.2797L104.362 54.0197C98.6116 55.5197 92.7616 54.7897 91.0316 52.4997C89.4016 50.3497 89.5816 44.3497 92.7416 38.6497L93.3916 37.6697L93.4016 37.6497Z"
        fill="white"
      />
    </g>
  </svg>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
